import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";
import { BootstrapInput, BootstrapLabel } from "components/Input";
import AddIcon from "@material-ui/icons/Add";
import { Link, useHistory } from "react-router-dom";

import { useAppContext } from "contexts";
import { AppTypes } from "store/types";

import { forgetPassword } from "apis/authentication";

const useStyles = makeStyles(
  (theme: any) => ({
    root: {},
    container: {
      color: "#1C2535",
      // minHeight: "100vh",
      minHeight: "calc(100vh - 64px)",
    },
    content: {
      padding: "56px 0px 60px 80px",
      backgroundColor: "white",
      width: "600px",
      ["@media (max-width:872px)"]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px",
      },
    },
    innerContent: {
      padding: "0px 80px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    fields: {
      flexBasis: "552px",
    },
    field: {
      width: "100%",
      marginTop: "24px",
      marginBottom: "12px",
    },
    buttonDiv: {
      marginTop: "30px",
      marginRight: "80px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0",
      },
    },
    button: {
      maxWidth: "200px",
      height: "40px",
    },
    feedback: {
      color: "#D81159",
      fontStyle: "italic",
      fontSize: "12px",
      lineHeight: "20px",
    },
    loader: {
      position: "absolute",
      top: "48%",
      left: "48%",
      zIndex: 5,
    },
  }),
  { index: 1 }
);

interface IProps {
  className?: string;
  registerRoute?: string;
  isApplying?: boolean;
}

interface IFields {
  email: string;
}

const ForgetPassword = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();

  const commonClasses = useCommonStyles();

  const [fields, setFields] = useState<IFields>({
    email: "",
  });

  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleChange =
    (key: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      setFields({
        ...fields,
        [key]: evt.target.value,
      });
    };

  const { dispatch } = useAppContext();

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setInProgress(true);

    forgetPassword(fields)
      .then(() => {
        setInProgress(false);
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Reset link",
            body: "Sent successfully",
          },
        });
        props.isApplying && history.push("/login");
      })
      .catch((err) => {
        console.log(err, "==================================");
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Failed",
            body: "No user found with this email",
          },
        });
        setInProgress(false);
      });
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, props.className)}>
      <Container
        className={clsx(commonClasses.center, classes.container)}
        maxWidth="lg"
      >
        <form
          className={clsx(
            commonClasses.containerShadow,
            commonClasses.border,
            commonClasses.borderRadius16,
            commonClasses.clearFix,
            classes.content
          )}
          onSubmit={handleSubmit}
        >
          <div className={clsx(commonClasses.textBig, commonClasses.pin)}>
            Forget Password
          </div>
          <div className={commonClasses.lineSeparator}></div>
          <div className={clsx(commonClasses.spread, classes.innerContent)}>
            <div className={classes.fields}>
              <div className={classes.field}>
                <BootstrapLabel htmlFor="email">Email</BootstrapLabel>
                <BootstrapInput
                  fullWidth={true}
                  id="email"
                  onChange={handleChange("email")}
                  placeholder="Enter email..."
                  required
                  type="email"
                  value={fields.email}
                />
              </div>
            </div>
          </div>
          <div
            className={clsx(
              commonClasses.spread,
              commonClasses.row,
              classes.buttonDiv
            )}
          >
            <div>
              <Link to={"/"}>Go Back</Link>
            </div>
            <div>
              <Button
                className={classes.button}
                color="primary"
                disableElevation
                disabled={inProgress}
                fullWidth={true}
                startIcon={<AddIcon />}
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ForgetPassword;
