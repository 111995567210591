import { Drawer, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useAppContext } from "contexts";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

interface IDATA {
  id: string;
}

export const FormSidebar = (props: IProps) => {
  const { dispatch, state } = useAppContext();
  const data: IDATA = useParams();
  const location = useLocation();
  // console.log("Data", location.pathname === "/pool/:id");
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      className={clsx(classes.root, classes.drawer, props.className)}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
    >
      <div>
        <div className={clsx(classes.toolbar, classes.logoWrapper)}>
          <Link to="/">
            <img
              alt="logo"
              className={classes.logo}
              src="/imgs/logo/logo-white.svg"
            />
          </Link>
        </div>
        {/* className={classes.itemContainer} */}
        <ListItem disableGutters>
          <ListItemText
            className={`${classes.item} ${
              location.pathname === "/pool/apply" && "active"
            }`}
            primary={
              <>
                <p className={classes.subHeading}>Step 1</p>
              </>
            }
            secondary={
              <>
                <span className={classes.mainHeading}>Apply</span>
              </>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            className={`${classes.item} ${
              location.pathname !== "/pool/apply" &&
              location.pathname !== "/pool/form" &&
              "active"
            }`}
            primary={
              <>
                <p className={classes.subHeading}>Step 2</p>
              </>
            }
            secondary={
              <>
                <span className={classes.mainHeading}>Revision</span>
              </>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            className={`${classes.item} ${
              location.pathname === "/pool/form" && "active"
            }`}
            primary={
              <>
                <p className={classes.subHeading}>Step 3</p>
              </>
            }
            secondary={
              <>
                <span className={classes.mainHeading}>Next Step</span>
              </>
            }
          />
        </ListItem>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100vh",
  },
  drawer: {
    width: theme.custom.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.custom.drawerWidth,
    backgroundColor: "#052B48",
    height: "100%",
    border: "none",
    justifyContent: "space-between",
  },
  toolbar: theme.mixins.toolbar,
  item: {
    color: "#9FD2F9",
    padding: "8px 10px 8px 13px",
    "&.active": {
      background: "#0C71BC !important",

      "& span": {
        color: "white",
      },
    },

    "&:hover": {
      background: "rgba(213, 237, 255, 0.15)",
    },
  },
  //   itemContainer: {
  //     position: "relative",
  //     "&::after": {
  //       content: "''",
  //       width: "90px",
  //       height: "64px",
  //       backgroundColor: "white",
  //       clipPath: "polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)",
  //       position: "absolute",
  //       right: 0,
  //     },
  //   },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: `calc(${theme.custom.appHeaderHeight} / 2)`,
  },
  link: {
    cursor: "pointer",
  },
  subHeading: {
    fontSize: "0.85rem",
    color: "#e5e5e5",
    marginTop: 0,
    marginBottom: 0,
  },
  mainHeading: {
    fontSize: "1.25rem",
    marginBottom: "8px",
    color: "#9FD2F9",
  },
}));

interface IProps {
  className?: string;
}

interface IProps {
  className?: string;
}
