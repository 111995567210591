import { API_URL } from "config/constants";
import axios from "axios";

export interface ILoginFields {
  email: string;
  password?: string;
}

export interface IRegisterFields {
  name: string;
  email: string;
  password: string;
}

export interface IFrogetPasswordFields {
  email: string;
  password?: string;
}

export interface IResetPasswordFields {
  password: string;
}

export const login = (fields: ILoginFields) => {
  return axios.post(`${API_URL}/auth/login`, {
    ...fields,
  });
};

export const resetPassword = (token: string, fields: IResetPasswordFields) => {
  return axios.post(`${API_URL}/auth/reset-password?token=${token}`, {
    ...fields,
  });
};

export const forgetPassword = (fields: IFrogetPasswordFields) => {
  return axios.post(`${API_URL}/auth/forgot-password`, {
    ...fields,
  });
};

export const register = (fields: IRegisterFields) => {
  return axios.post(`${API_URL}/auth/register`, {
    ...fields,
  });
};
