import { createStyles, withStyles } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";

export const UniversalInput = withStyles((theme: any) =>
  createStyles({
    root: {
      border: "1px solid #E8EBED",
      backgroundColor: theme.palette.common.white,
      borderRadius: "6px",

      "label + &": {
        marginTop: theme.spacing(0.5),
      },
    },
    adornedStart: {
      color: "#7F909F",
    },
    input: {
      padding: "5px 14px 5px 5px",
      color: "#1C2535",
    },
  })
)(OutlinedInput);
