import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";
import { BootstrapInput, BootstrapLabel } from "components/Input";
import AddIcon from "@material-ui/icons/Add";
import { Link, useHistory } from "react-router-dom";

import { useAppContext } from "contexts";
import { AppTypes } from "store/types";
import { register } from "apis/authentication";

const useStyles = makeStyles(
  (theme: any) => ({
    root: {},
    container: {
      color: "#1C2535",
      // minHeight: "100vh",
      minHeight: "calc(100vh - 64px)",
    },
    content: {
      padding: "56px 0px 60px 80px",
      backgroundColor: "white",
      width: "600px",
      ["@media (max-width:872px)"]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px",
      },
    },
    innerContent: {
      padding: "0px 80px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    fields: {
      flexBasis: "552px",
    },
    field: {
      width: "100%",
      marginTop: "24px",
      marginBottom: "12px",
    },
    buttonDiv: {
      marginTop: "30px",
      marginRight: "80px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0",
      },
    },
    button: {
      maxWidth: "200px",
      height: "40px",
    },
    feedback: {
      color: "#D81159",
      fontStyle: "italic",
      fontSize: "12px",
      lineHeight: "20px",
    },
    loader: {
      position: "absolute",
      top: "48%",
      left: "48%",
      zIndex: 5,
    },
    textOtherLinks: { fontSize: "14px" },
    otherLinks: {
      all: "unset",
      fontSize: "14px",
      color: "#0C71BC",
      cursor: "pointer",
    },
  }),
  { index: 1 }
);

interface IProps {
  className?: string;
  loginRoute?: string;
  isApplying?: boolean;
}

interface IFields {
  name: string;
  email: string;
  password: string;
}
interface IErrors {
  password?: string;
}

const Register = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();

  const commonClasses = useCommonStyles();

  const [fields, setFields] = useState<IFields>({
    name: "",
    email: "",
    password: "",
  });

  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleChange =
    (key: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      setFields({
        ...fields,
        [key]: evt.target.value,
      });
    };

  const [errors, setErrors] = useState<IErrors>({
    password: "",
  });

  const validateFields = useCallback((): boolean => {
    let valid = true;

    const newErrors: IErrors = {};
    if (
      fields.password &&
      (fields.password.length < 8 ||
        !fields.password.match(/\d/) ||
        !fields.password.match(/[a-zA-Z]/))
    ) {
      valid = false;
      newErrors.password =
        "Password should be at least 8 charaters and include at least one letter and one number.";
    }
    setErrors(newErrors);

    return valid;
  }, [fields]);

  useEffect(() => {
    validateFields();
  }, [fields.password, validateFields]);

  const { dispatch } = useAppContext();

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setInProgress(true);
    if (validateFields()) {
      register(fields)
        .then((response) => {
          console.info(response.data);
          dispatch({
            type: AppTypes.SetUser,
            payload: {
              token: response.data.tokens.access.token,
              user: response.data.user,
            },
          });
          setInProgress(false);
          props.isApplying && history.push("/application");
        })
        .catch((err) => {
          dispatch({
            type: AppTypes.AlertModal,
            payload: {
              show: true,
              title: "Registration failed",
              body: `Email already taken`,
            },
          });
          setInProgress(false);
        });
    } else {
      dispatch({
        type: AppTypes.AlertModal,
        payload: {
          show: true,
          title: "Registration failed",
          body: "Check Id and Password",
        },
      });
      setInProgress(false);
    }
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, props.className)}>
      <Container
        className={clsx(commonClasses.center, classes.container)}
        maxWidth="lg"
      >
        <form
          className={clsx(
            commonClasses.containerShadow,
            commonClasses.border,
            commonClasses.borderRadius16,
            commonClasses.clearFix,
            classes.content
          )}
          onSubmit={handleSubmit}
        >
          <div className={clsx(commonClasses.textBig, commonClasses.pin)}>
            Register
          </div>
          <div className={commonClasses.lineSeparator}></div>
          <div className={clsx(commonClasses.spread, classes.innerContent)}>
            <div className={classes.fields}>
              <div className={classes.field}>
                <BootstrapLabel htmlFor="name">Name</BootstrapLabel>
                <BootstrapInput
                  fullWidth={true}
                  id="name"
                  onChange={handleChange("name")}
                  placeholder="Enter name..."
                  required
                  type="name"
                  value={fields.name}
                />
              </div>
              <div className={classes.field}>
                <BootstrapLabel htmlFor="email">Email</BootstrapLabel>
                <BootstrapInput
                  fullWidth={true}
                  id="email"
                  onChange={handleChange("email")}
                  placeholder="Enter email..."
                  required
                  type="email"
                  value={fields.email}
                />
              </div>
              <div className={classes.field}>
                <BootstrapLabel htmlFor="password">Password</BootstrapLabel>
                <BootstrapInput
                  fullWidth={true}
                  id="password"
                  inputProps={{ min: 8 }}
                  onChange={handleChange("password")}
                  placeholder="Enter password..."
                  required
                  type="password"
                  value={fields.password}
                />
                <div className={classes.feedback}>{errors.password}</div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              commonClasses.spread,
              commonClasses.row,
              classes.buttonDiv
            )}
          >
            <div>
              <span className={classes.textOtherLinks}>
                Already have account?{" "}
              </span>
              <Link
                className={classes.otherLinks}
                to={props.loginRoute || "/admin/login"}
              >
                Login
              </Link>
            </div>
            <div>
              <Button
                className={classes.button}
                color="primary"
                disableElevation
                disabled={inProgress}
                fullWidth={true}
                startIcon={<AddIcon />}
                type="submit"
                variant="contained"
              >
                Register
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default Register;
