import { createStyles, withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";

export const MyChip = withStyles(() =>
  createStyles({
    root: {
      color: "#1C2535",
      "&.success": {
        color: "#59D5AF",
        backgroundColor: "#DEFFE5",
      },
      "&.danger": {
        color: "#D81159",
        backgroundColor: "#FFDEE4",
      },
      "&.blue": {
        color: "#095995",
        backgroundColor: "#D5EDFF",
      },
      "&.darkred": {
        color: "#8E0B3A",
        backgroundColor: "#FFDEE4",
      },
    },
    icon: {
      fontSize: 8,
      color: "#1C2535",
      ".success &": {
        color: "#59D5AF",
      },
      ".danger &": {
        color: "#D81159",
      },
      ".blue &": {
        color: "#59B5FF",
      },
      ".darkred &": {
        color: "#FD4975",
      },
    },
  })
)(Chip);

export const CandidateStatusChip = ({ status }: { status: string }) => {
  return (
    <MyChip
      className={
        status === "approved"
          ? "success"
          : status === "rejected"
          ? "danger"
          : ""
      }
      icon={<FiberManualRecordIcon />}
      label={status}
    />
  );
};

export const PoolStatusChip = ({ status }: { status: string }) => {
  return (
    <MyChip
      className={
        status === "active" ? "blue" : status === "filled" ? "darkred" : ""
      }
      icon={<FiberManualRecordIcon />}
      label={status}
    />
  );
};

export const ParticipantStatusChip = ({ status }: { status: string }) => {
  return (
    <MyChip
      className={
        status === "approved"
          ? "success"
          : status === "rejected"
          ? "danger"
          : ""
      }
      icon={<FiberManualRecordIcon />}
      label={status}
    />
  );
};

export const ApplicationStatusChip = ({ status }: { status: string }) => {
  return (
    <MyChip
      className={
        status === "approved"
          ? "success"
          : status === "rejected"
          ? "danger"
          : ""
      }
      icon={<FiberManualRecordIcon />}
      label={status}
    />
  );
};

export const ApplicationDeployChip = ({ status }: { status: string }) => {
  return (
    <MyChip
      className={
        status === "Deployed"
          ? "success"
          : status === "Rejected"
          ? "danger"
          : ""
      }
      icon={<FiberManualRecordIcon />}
      label={status}
    />
  );
};
