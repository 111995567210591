import { createStyles, withStyles } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

export const StripedTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "white",
      },
      cursor: "pointer",
    },
    head: {
      backgroundColor: "white",
      cursor: "default",
    },
  })
)(TableRow);
