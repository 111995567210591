const color = {
  transparent: "#0000",
  white: "#fff",
  black: "#000",
  primary: "#0C71BC",
  secondary: "#56606f",
  third: "#fce9ef",
  grey: "#7F909F",
};
export default color;
