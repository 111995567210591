import {
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { AddIcCallOutlined, ArrowBack, CloudUpload } from "@material-ui/icons";
import clsx from "clsx";
import { useAppContext, useConnectedWeb3Context } from "contexts";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import useCommonStyles from "styles/common";

import S3 from "react-aws-s3-typescript";

import AddIcon from "@material-ui/icons/Add";
import {
  BootstrapInput,
  BootstrapLabel,
  UniversalInput,
} from "components/Input";
import { IPool, createPool, getApplicationById } from "apis/application";
import {
  EAccess,
  EFundType,
  ENetwork,
  FUND_TYPE_TEXT,
  fundTypesPerNetwork,
} from "config/constants";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEagerConnect, useInactiveListener } from "hooks/injectedHook";
import { BootstrapSelect } from "components/Select";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AppActions, AppTypes } from "store/types";
import { useHistory, useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import CoverImage from "../../../../assets/pngs/upload-dummy.png";
import LogoImage from "../../../../assets/pngs/logo-dummy.png";
import moment from "moment";
import {
  ENVIRONMENT,
  mainnet_networkIds,
  testnet_networkIds,
} from "config/networks_chainIds";

interface IProps {
  id?: string;
}

const intialState = {
  // account: "",
  category: "",
  chain: "",
  chainId: 0,
  fundType: 0,
  address: "",
  access: 0,
  tradeValue: 0,
  minAlloc: 0,
  maxAlloc: 0,
  fee: 0,
  applicationId: "",
  deployStatus: false,
  tokenPrice: 0,
  poolId: 0,
  totalRaise: 0,
};

export const injected = new InjectedConnector({
  supportedChainIds: [1, 4, 56, 137, 80001, 97],
});

const getErrorMessage = (error: Error, dispatch: Dispatch<AppActions>) => {
  if (error instanceof NoEthereumProviderError) {
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.",
      },
    });
    // return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "You're connected to an unsupported network.",
      },
    });
    // return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "Please authorize this website to access your Ethereum account.",
      },
    });
    // return "Please authorize this website to access your Ethereum account.";
  } else {
    // console.error("Hello", error);
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "Request already in progress. Check the console for more details.",
      },
    });
    // return "An unknown error occurred. Check the console for more details.";
  }
};

interface IMAGES_TYPE {
  base64: string;
  file?: File;
}

export const DetailForm = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { state } = useAppContext();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { dispatch } = useAppContext();
  const history = useHistory();
  const [logoUpload, setLogoUpload] = useState<IMAGES_TYPE>({
    base64: LogoImage,
  });
  const [coverUpload, setCoverUpload] = useState<IMAGES_TYPE>({
    base64: CoverImage,
  });

  const refStartDate = useRef<HTMLInputElement>(null);
  const refEndDate = useRef<HTMLDivElement>(null);
  const refClaimDate = useRef<HTMLDivElement>(null);

  const minAllocation = useRef<HTMLInputElement>(null);

  // const startingDate = new Date(refStartDate.current?.value);

  const [fields, setFields] = useState<IPool>(intialState);
  const context = useWeb3React<Web3Provider>();
  const {
    account,
    activate,
    active,
    chainId,
    connector,
    deactivate,
    error,
    library,
  } = context;

  if (error) {
    getErrorMessage(error, dispatch);
  }

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState<any>();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const [applicationNetwork, setApplicationNetwork] = useState<string>("");

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      getApplicationById(id, {
        token: state.user.token,
      })
        .then(async (response) => {
          setApplicationNetwork(response.data.network);
        })
        .catch((err) => {
          console.log(err, "===================");
        });
    }
  }, [id]);

  // Reload on Change
  // window.ethereum &&
  //   window.ethereum.on("networkChanged", function (networkId: any) {
  //     // Time to reload your interface with the new networkId
  //     window.location.reload();
  //   });

  //testing
  // console.log("Accout", account);
  // console.log("active", active);
  // console.log("chainId", chainId);
  // // console.log("deactivate", deactivate);
  // console.log("library", library);
  // if (deactivate) {
  //   // console.log("deactived");
  //   setFields({
  //     ...fields,
  //     account: "",
  //   });
  // }

  // if (!active) {
  //   console.log("not Active");
  // }

  // if (account) {
  //   setFields({
  //     ...fields,
  //     account: account,
  //   });
  // }

  // useEffect
  const currentConnector = injected;
  const activating = currentConnector === activatingConnector;
  const connected = currentConnector === connector;
  const disabled = !triedEager || !!activatingConnector || connected || !!error;

  useEffect(() => {
    setActivatingConnector(currentConnector);
    activate(injected);
  }, [activate, currentConnector]);

  // console.log("disabled", disabled);
  // console.log("activating", activating);
  // console.log("Fileds", fields);

  // useEffect(() => {
  //   // const network = String(evt.target.value);
  //   const chainId = window.ethereum.chainId;
  //   // console.log("Chain ID in UseEffect", chainId);
  //   let network;
  //   if (chainId === "0x3") {
  //     network = ENetwork.ERC20;
  //     // console.log("Network", network);
  //     setFundTypes(fundTypesPerNetwork[network as ENetwork]);
  //     setFields({
  //       ...fields,
  //       chain: network,
  //       fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
  //     });
  //     // console.log("condition");
  //     // console.log("Fields", fields);
  //   } else if (chainId === "0x38") {
  //     network = ENetwork.BSC;
  //     // console.log("Network", network);
  //     setFundTypes(fundTypesPerNetwork[network as ENetwork]);
  //     setFields({
  //       ...fields,
  //       chain: network,
  //       fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
  //     });
  //     // console.log("condition");
  //     // console.log("Fields", fields);
  //   } else {
  //     network = ENetwork.POLYGON;
  //     // console.log("Network", network);
  //     setFundTypes(fundTypesPerNetwork[network as ENetwork]);
  //     setFields({
  //       ...fields,
  //       chain: network,
  //       fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
  //     });
  //     // console.log("condition");
  //     // console.log("Fields", fields);
  //   }
  // }, []);

  // // console.log("Data", data);
  // const connectWallet = async () => {
  //   if (window.ethereum) {
  //     //check if Metamask is installed
  //     try {
  //       const address = await window.ethereum.enable(); //connect Metamask
  //       const obj = {
  //         connectedStatus: true,
  //         status: "",
  //         address: address,
  //       };
  //       return obj;
  //     } catch (error) {
  //       return {
  //         connectedStatus: false,
  //         status: "🦊 Connect to Metamask using the button on the top right.",
  //       };
  //     }
  //   } else {
  //     return {
  //       connectedStatus: false,
  //       status:
  //         "🦊 You must install Metamask into your browser: https://metamask.io/download.html",
  //     };
  //   }
  // };
  // useEffect(() => connectWallet().then(()=>// console.log("Hello")), []);

  const [fundTypes, setFundTypes] = useState<EFundType[]>([]);

  // const handleNetworkSelectChange = (
  //   evt: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   const network = String(evt.target.value);

  //   setFundTypes(fundTypesPerNetwork[network as ENetwork]);

  //   setFields({
  //     ...fields,
  //     chain: network,
  //     fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
  //   });
  // };

  // const { account } = useConnectedWeb3Context();
  // // console.log("Account", account);

  useEffect(() => {
    // const network = String(evt.target.value);
    // const chainId = window.ethereum.chainId;

    console.log("Chain ID in UseEffect", chainId);
    error && getErrorMessage(error, dispatch);
    let network;
    if (ENVIRONMENT === "dev" && chainId === testnet_networkIds.ETH.RINKEBY) {
      network = ENetwork.ERC20;
      // console.log("Network", network);
      setFundTypes(fundTypesPerNetwork[network as ENetwork]);
      setFields({
        ...fields,
        chain: network,
        fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
      });
      // console.log("condition");
      // console.log("Fields", fields);
    } else if (
      ENVIRONMENT === "dev" &&
      chainId === testnet_networkIds.BSC.BscScan
    ) {
      network = ENetwork.BSC;
      // console.log("Network", network);
      setFundTypes(fundTypesPerNetwork[network as ENetwork]);
      setFields({
        ...fields,
        chain: network,
        fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
      });
      // console.log("condition");
      // console.log("Fields", fields);
    }

    if (ENVIRONMENT === "prod" && chainId === mainnet_networkIds.ETH.MAINNET) {
      network = ENetwork.ERC20;
      // console.log("Network", network);
      setFundTypes(fundTypesPerNetwork[network as ENetwork]);
      setFields({
        ...fields,
        chain: network,
        fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
      });
      // console.log("condition");
      // console.log("Fields", fields);
    } else if (
      ENVIRONMENT === "prod" &&
      chainId === mainnet_networkIds.BSC.BscScan
    ) {
      network = ENetwork.BSC;
      // console.log("Network", network);
      setFundTypes(fundTypesPerNetwork[network as ENetwork]);
      setFields({
        ...fields,
        chain: network,
        fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
      });
      // console.log("condition");
      // console.log("Fields", fields);
    } else if (ENVIRONMENT === "prod") {
      network = ENetwork.POLYGON;
      // console.log("Network", network);
      setFundTypes(fundTypesPerNetwork[network as ENetwork]);
      setFields({
        ...fields,
        chain: network,
        fundType: Number(fundTypesPerNetwork[network as ENetwork][0]),
      });
      // console.log("condition");
      // console.log("Fields", fields);
    }
  }, [chainId]);

  const CheckFalseNetwork = () => {
    if (
      ENVIRONMENT === "dev" &&
      chainId !== testnet_networkIds.ETH.RINKEBY &&
      chainId !== testnet_networkIds.BSC.BscScan &&
      chainId !== testnet_networkIds.Polygon.MUMBAI
    ) {
      return true;
    } else if (
      chainId === testnet_networkIds.ETH.RINKEBY &&
      applicationNetwork !== "eth"
    ) {
      return true;
    } else if (
      chainId === testnet_networkIds.BSC.BscScan &&
      applicationNetwork !== "bsc"
    ) {
      return true;
    } else if (
      chainId === testnet_networkIds.Polygon.MUMBAI &&
      applicationNetwork !== "polygon"
    ) {
      return true;
    } else if (
      ENVIRONMENT === "prod" &&
      chainId !== mainnet_networkIds.ETH.MAINNET &&
      chainId !== mainnet_networkIds.BSC.BscScan &&
      chainId !== mainnet_networkIds.Polygon.MATIC
    ) {
      return true;
    } else if (
      chainId === mainnet_networkIds.ETH.MAINNET &&
      applicationNetwork !== "eth"
    ) {
      return true;
    } else if (
      chainId === mainnet_networkIds.BSC.BscScan &&
      applicationNetwork !== "bsc"
    ) {
      return true;
    } else if (
      chainId === mainnet_networkIds.Polygon.MATIC &&
      applicationNetwork !== "polygon"
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (chainId && applicationNetwork) {
      const checkedValueNetwork = CheckFalseNetwork();
      if (checkedValueNetwork) {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "MetaMask",
            body: "You're connected to an unsupported network.",
          },
        });
      }
    }
  }, [chainId, applicationNetwork]);

  // Date functions
  const [startDate, setStartDateChange] = useState<Date | null>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    setStartDateChange(date);

    const cd = new Date();
    // console.log("CD", cd);
    // console.log("start date", startDate);
    10800;
  };

  const [claimDate, setClaimDateChange] = useState<Date | null>(new Date());

  const handleClaimDateChange = (date: Date | null) => {
    setClaimDateChange(date);
  };
  const [endDate, setEndDateChange] = useState<Date | null>(new Date());

  const handleEndDateChange = (date: Date | null) => {
    // let sd: Date;
    // if (refStartDate.current) {
    //   sd = new Date(refStartDate.current.value.toString());
    //   // console.log("Start Date", sd);
    // }
    // // console.log("refStartDate", moment.utc(refStartDate.current?.value));
    // console.log("Start Date", startDate?.valueOf());
    // console.log("End Date", date);

    // + (3600 * 1000 * 24) // add this for 1 day addition in current date

    // if (
    //   startDate &&
    //   date &&
    //   startDate?.valueOf() + 3600 * 1000 * 24 <= date?.valueOf()
    // ) {
    //   // console.log(
    //     "============================================================Matched=========================================="
    //   );
    // } else {
    //   // console.log(
    //     "============================================================Not Matched=========================================="
    //   );
    // }

    setEndDateChange(date);
  };

  const handleSelectChange =
    (key: string) => (evt: React.ChangeEvent<{ value: unknown }>) => {
      setFields({
        ...fields,
        [key]: evt.target.value,
      });
    };

  const handleChange =
    (key: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (
        [
          "totalRaise",
          "maxAlloc",
          "minAlloc",
          "tokenPrice",
          "fee",
          "tradeValue",
          "vesting",
        ].includes(key)
      ) {
        if (evt.target.value) {
          setFields({
            ...fields,
            [key]: parseInt(evt.target.value),
          });
        } else {
          setFields({
            ...fields,
            [key]: 0,
          });
        }
      } else {
        setFields({
          ...fields,
          [key]: evt.target.value,
        });
      }
    };

  const getBase64 = (file: File, cb: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };
  };

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    imageFor: string
  ) => {
    // e.preventDefault();
    // if (e.target.files?.length > 0) {
    //   // console.log("file selected");
    // }
    if (e.target.files) {
      const image = e.target?.files[0];
      if (
        (image && image.type === "image/png") ||
        image.type === "image/jpg" ||
        image.type === "image/jpeg"
      ) {
        const FR = new FileReader();
        if (imageFor === "logo") {
          const uploadImage = getBase64(image, (result: string) => {
            setLogoUpload({ base64: result, file: image });
            // console.log("Logo", image);
          });
          // // console.log("Image", uploadImage);
        } else {
          getBase64(image, (result: string) => {
            setCoverUpload({ base64: result, file: image });
            // console.log("coverImage", image);
          });
        }
      } else {
        // console.log("File not image");
      }
    } else {
      // console.log("No file selected");
      return false;
    }
    // const image = e.target?.files[0];

    // if (e.target.files) {
    //   const image = e.target?.files[0];
    //   // console.log("E", image);
    //   const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    //   if (!allowedExtensions.exec(image.name)) {
    //     alert("Invalid file type");
    //     // image.value = "";
    //     return false;
    //   }
    //   // console.log("Done");
    // }

    // return false;
    // // console.log("Clicked", imageFor);
  };

  // const validateDates = () => {
  //   if (
  //     startDate &&
  //     endDate &&
  //     claimDate &&
  //     startDate?.valueOf() + 3600 * 1000 * 24 <= endDate?.valueOf() &&
  //     endDate?.valueOf() + 3600 * 1000 * 24 <= claimDate?.valueOf()
  //   ) {
  //     // console.log(
  //       "============================================================Matched=========================================="
  //     );
  //   } else {
  //     // console.log(
  //       "============================================================Not Matched=========================================="
  //     );
  //     return;
  //   }
  // };

  useEffect(() => {
    console.log("Chain id", chainId);
    if (
      (ENVIRONMENT === "dev" && chainId !== testnet_networkIds.BSC.BscScan) ||
      error
    ) {
      error && getErrorMessage(error, dispatch);
    } else if (
      (ENVIRONMENT === "prod" && chainId !== mainnet_networkIds.BSC.BscScan) ||
      error
    ) {
      error && getErrorMessage(error, dispatch);
    }
  }, [error, chainId]);

  const ImageUploadToS3 = async (
    file: File,
    newFileName: string
  ): Promise<string> => {
    const config = {
      bucketName: "ideapadbuckt",
      // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
      region: "us-east-2",
      accessKeyId: "AKIAW6O5FM7FA7U4LWOV",
      secretAccessKey: "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",
    };

    // const config = {
    //   bucketName: "2million",
    //   // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    //   region: "us-east-2",
    //   accessKeyId: "AKIAW6O5FM7FHV6VS7WO",
    //   secretAccessKey: "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",
    // };

    let message = "";

    const r = (Math.random() + 1).toString(36).substring(7);

    const ReactS3Client = new S3(config);
    await ReactS3Client.uploadFile(file, `${newFileName}${r}`)
      .then((data) => {
        // console.log(data);
        if (data.status === 204) {
          message = data.location;
        } else {
          message = "fail";
        }
      })
      .catch((err) => {
        message = err;
      });
    return message;
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!account) {
      await setActivatingConnector(currentConnector);
      await activate(injected);
    }

    if (ENVIRONMENT === "dev") {
      if (
        chainId !== testnet_networkIds.ETH.RINKEBY &&
        chainId !== testnet_networkIds.Polygon.MUMBAI &&
        chainId !== testnet_networkIds.BSC.BscScan
      ) {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Meta Mask",
            body: `Not connected with supported chain`,
          },
        });
        return;
      }
    } else if (ENVIRONMENT === "prod") {
      if (
        chainId !== mainnet_networkIds.ETH.MAINNET &&
        chainId !== mainnet_networkIds.BSC.BscScan &&
        chainId !== mainnet_networkIds.Polygon.MATIC
      ) {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Meta Mask",
            body: `Not connected with supported chain`,
          },
        });
        return;
      }
    }

    const checkNetwork = CheckFalseNetwork();
    if (checkNetwork) {
      dispatch({
        type: AppTypes.AlertModal,
        payload: {
          show: true,
          title: "Meta Mask",
          body: `Not connected with supported chain`,
        },
      });
      return;
    }

    if (account) {
      error && getErrorMessage(error, dispatch);

      let logoImages = "";
      let coverImages = "";
      // validateDates();
      // if (fields.minAlloc > 0 && fields.maxAlloc && fields.maxAlloc) {
      // }

      const __totalRaise = fields?.totalRaise || 0;

      if (
        parseInt(fields?.maxAlloc.toString()) >
        parseInt(__totalRaise.toString())
      ) {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Warning",
            body: `Maximum Allocation should be less than to how much you want to raise with us...!`,
          },
        });
        return;
      }

      const cd = new Date();
      const currentTime = cd.valueOf() + 10800;
      if (
        startDate &&
        endDate &&
        claimDate &&
        startDate?.valueOf() >= currentTime &&
        startDate?.valueOf() + 60 <= endDate?.valueOf() &&
        endDate?.valueOf() + 60 <= claimDate?.valueOf()
      ) {
        // console.log("");
      } else {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Date",
            body: `Start Date should be after 3 hours
            End Date should be 1 minute after Start date 
            Claim Date should be 1 minute after End date`,
          },
        });
        return;
      }

      if (logoUpload.file && coverUpload.file) {
        setInProgress(true);
        await ImageUploadToS3(coverUpload.file, "cover")
          .then((data) => {
            coverImages = data;
          })
          .catch(() => {
            setInProgress(false);
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Upload Image",
                body: `Logo failed to upload`,
              },
            });
            return;
          });
        await ImageUploadToS3(logoUpload.file, "logo")
          .then((data) => {
            setInProgress(false);
            logoImages = data;
          })
          .catch(() => {
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Upload Image",
                body: `Cover failed to upload`,
              },
            });
            return;
          });
      } else {
        setInProgress(false);
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Images",
            body: `Logo & Cover image required`,
          },
        });
        return;
      }

      console.log("Files after image", fields);

      createPool(
        {
          ...fields,
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
          claimDate: claimDate?.toISOString(),
          applicationId: id,
          account: account,
          chainId: chainId,
          meta: {
            logo: logoImages,
            cover: coverImages,
          },
        },
        {
          token: state.user.token,
        }
      )
        .then((result) => {
          setInProgress(false);
          dispatch({
            type: AppTypes.AlertModal,
            payload: {
              show: true,
              title: "Pool",
              body: "Successfully submitted!",
            },
          });
        })
        .then(() =>
          // For rerouting to My Application and modal off
          setTimeout(() => {
            dispatch({ type: AppTypes.AlertModal, payload: { show: false } });
            history.push("/pools");
          }, 3000)
        )
        .catch((err) => {
          console.error(err);
          setInProgress(false);
          if (err.response) {
            console.error(err.response);
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Application",
                body: err.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          setInProgress(false);
        });
    }
  };

  const maxAllocationNumber = (n: number) => {
    const num: number = parseInt(`${n}`) + parseInt("1");
    return num;
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container} maxWidth="lg">
        <div onClick={() => history.push("/")}>
          <p className={classes.backIconContainer}>
            <ArrowBack className={classes.backIcon} /> Go Back
          </p>
        </div>
        <form
          className={clsx(
            commonClasses.containerShadow,
            commonClasses.border,
            commonClasses.borderRadius16,
            commonClasses.clearFix,
            classes.content
          )}
          onSubmit={handleSubmit}
        >
          <div className={clsx(commonClasses.textBig, commonClasses.pin)}>
            Apply for Pool
          </div>
          <div className={commonClasses.lineSeparator}></div>
          <div className={classes.innerContent}>
            <Grid className={classes.fields} container>
              <div className={commonClasses.textMid}>Basic Information</div>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="account">Account</BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="account"
                    required
                    type="text"
                    value={account ? account : ""}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="chainSelect">Network</BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapSelect
                    className={classes.select}
                    disabled
                    displayEmpty
                    fullWidth={true}
                    id="chainSelect"
                    input={<UniversalInput />}
                    // inputProps={{ "aria-label": "select network" }}
                    // onChange={handleNetworkSelectChange}
                    required
                    // value={fields.chain}
                    value={applicationNetwork}
                  >
                    <MenuItem value={ENetwork.ERC20}>Ethereum</MenuItem>
                    <MenuItem value={ENetwork.BSC}>
                      Binance Smart Chain
                    </MenuItem>
                    <MenuItem value={ENetwork.POLYGON}>
                      Polygon (Matic)
                    </MenuItem>
                  </BootstrapSelect>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="fundTypeSelect">
                    Select Fund Type
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapSelect
                    className={classes.select}
                    // displayEmpty
                    fullWidth={true}
                    id="fundTypeSelect"
                    input={<UniversalInput />}
                    // inputProps={{ "aria-label": "select fund type" }}
                    onChange={handleSelectChange("fundType")}
                    required
                    // value={fields.fundType}
                    value={
                      applicationNetwork === "bsc"
                        ? 2
                        : applicationNetwork === "eth"
                        ? 0
                        : 3
                    }
                  >
                    {/* <MenuItem disabled value={-1}>
                      <em>Select...</em>
                    </MenuItem> */}
                    {/* {fundTypes.map((fundType) => ( */}
                    <MenuItem
                      key={
                        applicationNetwork === "bsc"
                          ? 2
                          : applicationNetwork === "eth"
                          ? 0
                          : 3
                      }
                      value={
                        applicationNetwork === "bsc"
                          ? 2
                          : applicationNetwork === "eth"
                          ? 0
                          : 3
                      }
                    >
                      {
                        FUND_TYPE_TEXT[
                          applicationNetwork === "bsc"
                            ? 2
                            : applicationNetwork === "eth"
                            ? 0
                            : 3
                        ]
                      }
                    </MenuItem>
                    {/* ))} */}
                  </BootstrapSelect>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="address">
                    Token Address
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="address"
                    onChange={handleChange("address")}
                    placeholder="Token Address..."
                    required
                    type="text"
                    value={fields.address}
                  />
                </Grid>
              </Grid>

              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="address">
                    Vesting Percentage
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="vesting"
                    onChange={handleChange("vesting")}
                    placeholder="Vesting Percentage"
                    required
                    type="text"
                    value={fields.vesting}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid className={clsx(classes.fields, classes.sections)} container>
              <div className={commonClasses.textMid}>Pool Information</div>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="tradeValue">
                    Trade Value (in USD)
                    {/* Trade Value{" "}
                    {fields.chain && (
                      <>
                        (in{" "}
                        {FUND_TYPE_TEXT[Number(fields.fundType) as EFundType]})
                      </>
                    )} */}
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    disabled={fields.chain === undefined || fields.chain === ""}
                    fullWidth={true}
                    id="tradeValue"
                    inputProps={{ min: 0.0000001, step: ".0000001" }}
                    onChange={handleChange("tradeValue")}
                    placeholder="Trade value..."
                    required
                    type="text"
                    value={fields.tradeValue}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="fee">
                    Fee Amount ( &gt;2% )
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="fee"
                    inputProps={{ min: 0, max: 2 }}
                    onChange={handleChange("fee")}
                    placeholder="Enter fee percent..."
                    required
                    type="text"
                    value={fields.fee}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="tokenPrice">
                    Token Price
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="fee"
                    inputProps={{ min: 0.001, step: ".001" }}
                    onChange={handleChange("tokenPrice")}
                    placeholder="Enter Token Price"
                    required
                    type="text"
                    value={fields.tokenPrice}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="minAlloc">
                    Min. Allocation (Min. # of Tokens Allowed per Wallet)
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="minAlloc"
                    inputProps={{ min: 1 }}
                    inputRef={minAllocation}
                    onChange={handleChange("minAlloc")}
                    placeholder="Min. allocation"
                    required
                    type="text"
                    value={fields.minAlloc}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="maxAlloc">
                    Max. Allocation (Max. # of Tokens Allowed per Wallet)
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="maxAlloc"
                    inputProps={{
                      min: maxAllocationNumber(fields.minAlloc),
                    }}
                    onChange={handleChange("maxAlloc")}
                    placeholder="Max. allocation"
                    required
                    type="text"
                    value={fields.maxAlloc}
                  />
                </Grid>
              </Grid>

              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="totalRaise">
                    How much are you looking to raise with us?
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapInput
                    fullWidth={true}
                    id="totalRaise"
                    inputProps={{ min: 1 }}
                    onChange={handleChange("totalRaise")}
                    placeholder="Any product & MVP live? Testnet?"
                    required
                    type="text"
                    value={fields.totalRaise}
                  />
                </Grid>
              </Grid>

              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="accessSelect">Access</BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <BootstrapSelect
                    className={classes.select}
                    displayEmpty
                    fullWidth={true}
                    id="accessSelect"
                    input={<UniversalInput />}
                    inputProps={{ min: 1 }}
                    // inputProps={{ "aria-label": "select access" }}
                    onChange={handleSelectChange("access")}
                    required
                    value={fields.access}
                  >
                    <MenuItem value={EAccess.Private}>Private</MenuItem>
                    <MenuItem value={EAccess.Public}>Public</MenuItem>
                  </BootstrapSelect>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="startDate">
                    Start Date
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      className={classes.datetimeField}
                      disablePast
                      // error={true}
                      // helperText={"Hello meello"}
                      inputRef={refStartDate}
                      inputVariant="outlined"
                      onChange={handleStartDateChange}
                      // ref={refStartDate}
                      required
                      value={startDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="endDate">End Date</BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      className={classes.datetimeField}
                      disablePast
                      inputVariant="outlined"
                      minDate={refStartDate.current}
                      onChange={handleEndDateChange}
                      required
                      value={endDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                className={classes.field}
                container
                spacing={3}
              >
                <Grid item md={4} xs={12}>
                  <BootstrapLabel htmlFor="startDate">
                    Claim Date
                  </BootstrapLabel>
                </Grid>
                <Grid item md={8} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      // InputProps={{dat}}
                      className={classes.datetimeField}
                      disablePast
                      inputVariant="outlined"
                      onChange={handleClaimDateChange}
                      required
                      value={claimDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                className={clsx(classes.fields, classes.sections)}
                container
              >
                <div className={commonClasses.textMid}>Pool Images</div>
                <Grid
                  alignItems="center"
                  className={`${classes.field} ${classes.alignStart}`}
                  container
                  spacing={3}
                >
                  <Grid item md={4} xs={12}>
                    <BootstrapLabel>
                      <div className={classes.uploadButtonContainer}>
                        <BootstrapLabel htmlFor="logo">Logo</BootstrapLabel>
                        <Button
                          className={classes.uploadButton}
                          color="default"
                          component="label"
                          startIcon={<CloudUpload />}
                          variant="contained"
                        >
                          Upload
                          <input
                            accept="image/*"
                            hidden
                            id="logo"
                            onChange={(e) => handleImageUpload(e, "logo")}
                            type="file"
                          />
                        </Button>
                      </div>
                      <div className={classes.uploadButtonContainer}>
                        <BootstrapLabel htmlFor="coverImage">
                          Cover Image
                        </BootstrapLabel>
                        <Button
                          className={classes.uploadButton}
                          color="default"
                          component="label"
                          startIcon={<CloudUpload />}
                          variant="contained"
                        >
                          Upload
                          <input
                            accept="image/*"
                            hidden
                            id="coverImage"
                            name="coverImage"
                            onChange={(e) => handleImageUpload(e, "coverImage")}
                            type="file"
                          />
                        </Button>
                      </div>
                    </BootstrapLabel>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <div className={classes.cardContainer}>
                      <Card className={classes.card} variant="outlined">
                        <img
                          alt="idea token"
                          className={classes.coverImage}
                          src={coverUpload.base64}
                        />
                        <div className={classes.poolContainer}>
                          <div className={classes.logoContainer}>
                            <img
                              alt="idea token"
                              className={classes.poolImg}
                              src={logoUpload.base64}
                            />
                          </div>
                          <Skeleton
                            animation={false}
                            height={40}
                            width={"40%"}
                          ></Skeleton>
                          <Skeleton
                            animation={false}
                            height={28}
                            width={"95%"}
                          ></Skeleton>
                          <Skeleton
                            animation={false}
                            height={28}
                            width={"100%"}
                          ></Skeleton>
                          <Skeleton
                            animation={false}
                            height={28}
                            width={"60%"}
                          ></Skeleton>
                          {/* <div className={classes.skeletonBox}></div> */}
                        </div>
                      </Card>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <p className={classes.note}>
            Note: Gas fee will be charged when deploying pool.
          </p>
          <div className={classes.buttonDiv}>
            {/*<Button
              className={classes.button}
              color="primary"
              disableElevation
              fullWidth={true}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Connect Wallet
            </Button>*/}
            <Button
              className={classes.button}
              color="primary"
              disableElevation
              disabled={inProgress}
              fullWidth={true}
              startIcon={<AddIcon />}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {},
  container: {
    color: "#1C2535",
    marginTop: "40px",
    marginBottom: "40px",
  },
  content: {
    padding: "56px 0px 60px 80px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px 30px 30px",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    margin: "auto",
    border: "1px solid #E8EBED",
    boxShadow: "0px 12px 20px -8px rgba(5, 43, 72, 0.12)",
    borderRadius: "16px",
    backgroundColor: "white",
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    maxWidth: "360px",
    "&:hover": {
      border: "1px solid #59B5FF",
      boxShadow: "0px 16px 24px -12px rgba(89, 181, 255, 0.24)",
    },
  },
  poolContainer: {
    padding: "0 17px 24px 17px",
    width: "100%",
    textAlign: "left",
    marginTop: "-35px",
  },
  logoContainer: {
    backgroundColor: "#fff",
    border: "0.375rem solid #fff",
    borderRadius: "0.75rem",
    width: "max-content",
  },
  poolImg: {
    height: "5rem",
    width: "5rem",
    borderRadius: "0.5rem",
  },
  poolTitle: {
    marginTop: "4px",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "bold",
    color: "#1C2535",
    display: "flex",
    justifyContent: "space-between",
  },
  coverImage: {
    width: "100%",
    objectFit: "cover",
    height: "250px",
  },
  innerContent: {
    padding: "0px 80px 0px 0px",

    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 0px",
    },
  },
  fields: {
    // flexBasis: "552px",
    flex: 1,
  },
  field: {
    width: "100%",
    marginTop: "24px",
    marginBottom: "12px",
  },
  alignStart: {
    alignItems: "start",
  },
  select: {
    height: "40px",
    marginTop: theme.spacing(1),
  },
  buttonDiv: {
    marginTop: "30px",
    marginRight: "80px",
    float: "right",
  },
  button: {
    maxWidth: "200px",
    height: "40px",
  },
  uploadButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px",
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  uploadButton: {
    background: "transparent",
    maxWidth: "200px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#0C71BC",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
  },
  cardContainer: {
    textAlign: "center",
  },
  feedback: {
    color: "#D81159",
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "20px",
  },
  datetimeField: {
    // height: "40px",
    // marginTop: "10px",
    // minWidth: "250px",
    border: "1px solid #ced4da",
    flexDirection: "inherit",
    borderRadius: "4px",
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
  sections: {
    marginTop: "16px",
  },
  fontNormal: {
    fontWeight: 400,
  },
  note: {
    width: "100%",
    opacity: 0.8,
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    opacity: "0.5",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  backIcon: {
    fontSize: "1rem",
    marginRight: "0.25rem",
  },
}));
