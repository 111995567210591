import { Button, Modal, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";

import { AppTypes } from "store/types";
import { useAppContext, useConnectedWeb3Context, useGlobal } from "contexts";

import { NULL_ADDRESS } from "utils/token";
import PoolService from "services/pool";
import { ERC20Service } from "services/erc20";
import { BigNumber } from "ethers";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  content: {
    outline: "none",
    backgroundColor: "white",
    width: 400,
    padding: theme.spacing(4),
    userSelect: `none`,
    borderRadius: "6px",

    [theme.breakpoints.down("xs")]: {
      width: "328px",
    },
  },
  title: {
    color: "#1C2535",
    fontSize: 24,
    lineHeight: "32px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  button: {
    width: 120,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  gap: {
    width: "16px",
    height: "16px",
  },
}));

interface IProps {
  visible: boolean;
  onClose: () => void;
}

export const JoinModal = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { onClose, visible } = props;

  const { dispatch, state } = useAppContext();

  const { account, library: provider } = useConnectedWeb3Context();

  const { setLoading } = useGlobal();

  const { amount, pool } = state.modals.join;

  const isMainCoin = pool?.weiToken === NULL_ADDRESS;

  const wAddress = pool?.token || "";
  const wAmount = BigNumber.from(amount || 0)
    .mul(1e9)
    .mul(1e9);

  const handleOK = async () => {
    if (!provider) return;
    if (!account) return;

    dispatch({ type: AppTypes.JoinModal, payload: { show: false } });

    const poolService = new PoolService(provider, account, wAddress);

    try {
      if (isMainCoin) {
        setLoading(true, "Follow wallet instructions");
        const txHash = await poolService.buyWithEth(wAmount);
        setLoading(true, "Please wait until the transaction is confirmed!");
        await provider.waitForTransaction(txHash);
      } else {
        const erc20Service = new ERC20Service(
          provider,
          account,
          pool?.weiToken || ""
        );
        setLoading(true, "Loading ...");

        // // console.log(await erc20Service.totalSupply());

        const hasEnoughAllowance = await erc20Service.hasEnoughAllowance(
          account || "",
          wAddress,
          wAmount
        );

        if (!hasEnoughAllowance) {
          setLoading(true, "Approving tokens ... Follow wallet instructions");
          const txHash = await erc20Service.approveUnlimited(wAddress);
          setLoading(
            true,
            "Approving tokens ... Please wait until the transaction is confirmed!"
          );
          await provider.waitForTransaction(txHash);
        }
        setLoading(true, "Follow wallet instructions");
        const txHash = await poolService.buy(wAmount);
        setLoading(true, "Please wait until the transaction is confirmed!");
        await provider.waitForTransaction(txHash);
      }
      setLoading(true, "Reloading ...");
      // await loadClaimable();
      // await reloadPoolInfo();
      setLoading(false);
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }

    /* dispatch({
      type: AppTypes.JoinPool,
      payload: {
        poolId: "todo: props.poolId",
        participant: {
          id: account,
          address: account,
          amount: 1,
          baseAmount: 1,
          timestamp: String(Date.now()),
          status: "new",
        },
      },
    }); */
  };

  const handleCancel = () => {
    dispatch({ type: AppTypes.JoinModal, payload: { show: false } });
  };

  return (
    <Modal
      className={clsx(classes.modal, commonClasses.modalShadow)}
      disableBackdropClick
      onClose={onClose}
      open={visible}
    >
      <div className={classes.content}>
        <Typography className={classes.title} component="div">
          Join the Pool
        </Typography>
        <div>
          <p>
            Are you sure you want to join the {`${pool?.name || ""}`} pool with
            the amount of
            {` ${amount} ${pool?.symbol || "MTCL"}`}?
          </p>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            className={clsx(commonClasses.button, classes.button)}
            color="primary"
            disableElevation
            disabled={!account}
            onClick={handleOK}
            variant="contained"
          >
            Continue
          </Button>
          <div className={classes.gap}></div>
          <Button
            className={clsx(commonClasses.button, classes.button)}
            color="primary"
            disableElevation
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
