import { Button, Hidden, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { IMenuItem } from "config/menu";
import { AppTypes } from "store/types";
import { useAppContext } from "contexts";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",

    height: "100vh",
    backgroundColor: "#0B1322",
  },
  container: {
    position: "relative",

    width: "312px",

    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  closeIcon: {
    display: "block",
    position: "absolute",

    color: "white",

    top: "25px",
    left: "5px",
  },
  logo: {
    marginTop: "28px",
  },
  logoImg: {
    width: "127px",
  },
  navWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",

    paddingTop: "36px",
    paddingBottom: "36px",

    height: "calc(100vh - 71px)",
  },
  upperWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bottomWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  primaryButton: {
    backgroundColor: "#0C71BC",
    color: "#fff",
  },
}));

const useItemStyles = makeStyles(() => ({
  root: {},

  menuButtonText: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#9FD2F9",
  },
  menuButton: {
    width: "120px",
    height: "40px",
    fontSize: "16px",
    borderRadius: "3px",
    padding: "11px 24px 10px 20px",
  },
  noDecoration: {
    textDecoration: "none !important",
  },
  menuItem: {
    width: "312px",
    height: "40px",
    marginBottom: "8px",
    order: 2,
  },
  button: {
    order: 1,
    marginBottom: "36px",
  },
  active: {
    position: "relative",

    "& span.MuiButton-label": {
      color: "#FFFFFF",
    },

    "&::after": {
      content: "''",
      width: "32px",
      height: "4px",
      background: "#0C71BC",

      position: "absolute",
      bottom: "4px",
      left: "calc(50% - 16px)",
    },
  },
}));

interface IItemProps {
  className?: string;
  item: IMenuItem;
  active?: boolean;
}

export const MobileMenuItem = (props: IItemProps) => {
  const classes = useItemStyles();

  const { active, item } = props;

  if (item.link) {
    if (item.external) {
      return (
        <a
          className={clsx(classes.noDecoration, classes.menuItem, {
            [classes.active]: active,
          })}
          href={item.link}
        >
          <Button
            classes={{ text: classes.menuButtonText }}
            color="secondary"
            fullWidth={true}
          >
            {item.name}
          </Button>
        </a>
      );
    } else {
      return (
        <Link
          className={clsx(classes.noDecoration, classes.menuItem, {
            [classes.active]: active,
          })}
          to={item.link}
        >
          <Button
            classes={{ text: classes.menuButtonText }}
            color="secondary"
            fullWidth={true}
          >
            {item.name}
          </Button>
        </Link>
      );
    }
  }
  return (
    <Button
      className={clsx(classes.menuItem, classes.button)}
      classes={{ root: classes.menuButton }}
      color="primary"
      disableElevation
      onClick={item.onClick}
      startIcon={<AccountBalanceWalletIcon />}
      variant="contained"
    >
      {item.name}
    </Button>
  );
};

interface IProps {
  className?: string;
  onMenuClose: (event: React.MouseEvent<HTMLElement> | undefined) => void;
  items: IMenuItem[];
  secondaryItems?: IMenuItem[];
  children: React.ReactNode | React.ReactNode[];
}

export const MobileMenu = (props: IProps) => {
  const { onMenuClose } = props;
  const classes = useStyles();

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    onMenuClose(event);
  };

  const { dispatch, state } = useAppContext();

  const location = useLocation();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onMenuClose(undefined);
    }
  }, [location.pathname, onMenuClose]);

  return (
    <Hidden smUp>
      <div className={clsx(classes.root, props.className, "mobile-menu")}>
        <div className={classes.container}>
          <div className={classes.closeIcon} onClick={handleMenuClose}>
            <CloseIcon />
          </div>
          <div className={classes.logo}>
            <Link to="/">
              <img
                alt="logo"
                className={classes.logoImg}
                src="/imgs/logo/logo-white.svg"
              />
            </Link>
          </div>
          <div className={classes.navWrapper}>
            <div className={classes.upperWrapper}>
              {props.children}
              {props.items &&
                props.items.map((item: IMenuItem) => (
                  <MobileMenuItem
                    active={item.link === location.pathname}
                    item={item}
                    key={item.name}
                  />
                ))}
            </div>
            <div className={classes.bottomWrapper}>
              {state.user.token && state.user.token?.length > 1 && (
                <Button
                  className={classes.primaryButton}
                  onClick={(e) => {
                    localStorage.removeItem("USER");
                    dispatch({
                      type: AppTypes.SetUser,
                      payload: {
                        token: "",
                        user: {},
                      },
                    });
                    handleMenuClose(e);
                  }}
                  variant="outlined"
                >
                  Logout
                </Button>
              )}
              {props.secondaryItems &&
                props.secondaryItems.map((item: IMenuItem) => (
                  <MobileMenuItem
                    active={item.link === location.pathname}
                    item={item}
                    key={item.name}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Hidden>
  );
};
