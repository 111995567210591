export const mainnet_networkIds = {
  ETH: {
    MAINNET: 1,
  },
  Polygon: {
    MATIC: 137,
  },
  BSC: {
    BscScan: 56,
  },
};

export const testnet_networkIds = {
  ETH: {
    RINKEBY: 4,
    ROPSTEN: 3,
  },
  Polygon: {
    MUMBAI: 80001,
  },
  BSC: {
    BscScan: 97,
  },
};

export const ENVIRONMENT =
  process.env.REACT_APP_Environment === "development"
    ? "dev"
    : process.env.REACT_APP_Environment === "prod"
    ? "prod"
    : "";
