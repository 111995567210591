import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useAppContext } from "contexts";
import { AppTypes, IApplication } from "store/types";
import useCommonStyles from "styles/common";
import {
  EAccess,
  FUND_TYPE_TEXT,
  PROJECT_CATEGORY_TEXT,
  PROJECT_STATUS_TEXT,
} from "config/constants";
import { useHistory, useParams } from "react-router-dom";
import {
  BootstrapInput,
  BootstrapLabel,
  UniversalInput,
} from "components/Input";
import { BootstrapSelect } from "components/Select";
import { getDateTime } from "utils/datetime";
import { IPool, processPool } from "apis/application";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  header: {
    padding: "32px",
  },
  fields: {},
  field: {
    display: "flex",
    flexDirection: "column",

    marginBottom: "16px",
    width: "90%",
  },
  select: {
    height: "40px",
  },
  title: {
    marginBottom: "24px",
  },
  statusButtons: {
    paddingRight: "60px",
    justifyContent: "flex-end",
  },
  button: {
    minWidth: "150px",
    height: "46px",
    "&.reject": {
      // FD4975
      backgroundColor: "#FD4975",
      "&:disabled": {
        backgroundColor: "#FD4975",
      },
    },
    "&:disabled": {
      backgroundColor: "#446C8A",
    },
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
}));

interface IProps {
  className?: string;
  pool: IPool;
}

export const AdminPoolDetailPanel = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();

  const [inProgress, setInProgress] = useState<boolean>(false);

  const { dispatch, state } = useAppContext();

  // console.log("Prop Data", props.pool);

  // const { id } = useParams<{ id: string }>();

  // const application = useMemo(
  //   () => state.applications.find((app) => app._id === id),
  //   [id, state.applications]
  // );

  const handleStatusChange = (action: string, id: string) => {
    {
      state &&
        processPool(id, action, { token: state.user.token })
          .then((result) => {
            setInProgress(false);
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Pool",
                body: `Successfully ${result.data.status}!`,
              },
            });
          })
          .then(() =>
            // For rerouting to My Application and modal off
            setTimeout(() => {
              dispatch({ type: AppTypes.AlertModal, payload: { show: false } });
              history.push("/admin/pools");
            }, 3000)
          )
          .catch((err) => {
            console.log("Error", err);
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Error",
                body: "Something wen't wrong - Re check values",
              },
            });
            setInProgress(false);
          })
          .finally(() => {
            setInProgress(false);
          });
    }
  };

  // const handleStatusChangea = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     // console.log("E", event);
  // if (!application) return;

  // setInProgress(true);

  // const action = event.target.checked ? "approve" : "reject";

  // processApplication(
  //   application.address!,
  //   action,
  //   {
  //     token: state.user.token,
  //   },
  //   { chainId: window.ethereum.chainId }
  // )
  //   .then((response) => {
  //     console.info(response.data);

  //     const app: IApplication = response.data;

  //     dispatch({
  //       type: AppTypes.SetApplication,
  //       payload: {
  //         application: app,
  //         applicationId: app.id,
  //       },
  //     });

  //     setInProgress(false);
  //   })
  //   .catch((err) => {
  //     console.error(err.response);
  //     if (err.response.status === 401) {
  //       dispatch({
  //         type: AppTypes.SetUser,
  //         payload: {
  //           token: "",
  //           user: {},
  //         },
  //       });
  //     }
  //   });
  //   },
  //   [application, dispatch, state.user.token]
  // );

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.header}>
        <div className={clsx(commonClasses.textMid, commonClasses.pin)}>
          Pool Details
        </div>
      </div>
      {props.pool && (
        <>
          <Grid container>
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.title, commonClasses.textMid)}>
                Pool Information
              </div>
              <div className={classes.fields}>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="projectName">
                    Project Name
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="projectName"
                    required
                    type="text"
                    value={props.pool.applicationId.projectName}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="user">User Id</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="user"
                    required
                    type="text"
                    value={props.pool.user}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="account">Account</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="account"
                    required
                    type="text"
                    value={props.pool.account}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="coin">Coin</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="coin"
                    required
                    type="text"
                    value={props.pool.chain}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="address">
                    Token Address
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="address"
                    required
                    type="text"
                    value={props.pool.address}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="tradeValue">
                    Trade Value(in BSC)
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="tradeValue"
                    required
                    type="text"
                    value={props.pool.tradeValue}
                  />
                </div>
                {/* <div className={classes.field}>
                  <BootstrapLabel htmlFor="feeAmount">
                    Fee Amount
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="feeAmount"
                    required
                    type="text"
                    // value={application.twitter}
                  />
                </div> */}
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="minAlloc">
                    Min. Allocation (Min. # of Tokens Allowed per Wallet)
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="minAlloc"
                    required
                    type="text"
                    value={props.pool.minAlloc}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="maxAlloc">
                    Max. Allocation (Max. # of Tokens Allowed per Wallet)
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="maxAlloc"
                    required
                    type="text"
                    value={props.pool.maxAlloc}
                  />
                </div>

                <div className={classes.field}>
                  <BootstrapLabel htmlFor="vesting">
                    Vesting Percentage
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="vesting"
                    required
                    type="text"
                    value={props.pool.vesting}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.title, commonClasses.textMid)}>
                Schedule Information
              </div>
              <div className={classes.fields}>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="access">Access</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="access"
                    required
                    type="text"
                    value={props.pool.access}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="startDate">
                    Start Date
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="startDate"
                    required
                    type="text"
                    value={props.pool.startDate}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="endDate">End Date</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="endDate"
                    required
                    type="text"
                    value={props.pool.endDate}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="claimDate">
                    Claim Date
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="claimDate"
                    required
                    type="text"
                    value={props.pool.claimDate}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="maxAlloc">Pool ID</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="maxAlloc"
                    required
                    type="text"
                    value={props.pool.poolId}
                  />
                </div>
                <div className={clsx(classes.title, commonClasses.textMid)}>
                  Status Information
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="status">Status</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="status"
                    required
                    type="text"
                    value={props.pool.status}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="deployStatus">
                    Deploy Status
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="deployStatus"
                    required
                    type="text"
                    value={props.pool.deployStatus}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                // disabled={props.pool.status === "approved" ? true : false}
                onClick={() =>
                  handleStatusChange("approve", props.pool._id || "")
                }
                variant="contained"
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={`${classes.button} reject`}
                color="secondary"
                disabled={props.pool.status === "approved" ? true : false}
                onClick={() =>
                  handleStatusChange("reject", props.pool._id || "")
                }
                variant="contained"
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
