import { Hidden, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import useCommonStyles from "styles/common";

import { AdminHeader } from "./components/AdminHeader";
import { AdminSidenav } from "./components/AdminSidenav";
export * from "./components";

import { useAppContext } from "contexts";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: { display: "flex" },
  content: {
    flexGrow: 1,
    backgroundColor: "#F4F8FA",
  },
  toolbar: theme.mixins.toolbar,
}));

interface IProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const AdminLayout = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { state } = useAppContext();

  if (
    !state ||
    state.user.token === undefined ||
    state.user.user === undefined ||
    state.user.token.length === 0
  ) {
    return <Redirect to="/login" />;
  }

  if (state && state.user.user.role === "user") {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      {/* <Hidden  */}
      <AdminHeader />
      <AdminSidenav />
      <main className={clsx(classes.content, commonClasses.scroll)}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};
