import { Container, makeStyles } from "@material-ui/core";
import Login from "pages/admin/Login";
import React from "react";

export const UserApplicationLogin = () => {
  const classes = useStyles();

  return (
    <div>
      <Login isApplying registerRoute={"/register"} />
    </div>
  );
};

const useStyles = makeStyles(() => ({}));
