import { Button, makeStyles } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { MyChip } from "components/Chip";
import React, { useEffect, useState } from "react";
import { shortenAddress } from "utils";
import { formatBigNumber } from "utils/formatNumber";
import { useConnectedWeb3Context } from "contexts";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  infoDiv: {
    marginRight: "16px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",

      marginRight: 0,
      marginBottom: "16px",

      display: "flex",
      justifyContent: "space-between",
    },
  },
  chip: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#F4F8FA",
    },
  },
  button: {
    height: "40px",
    fontSize: theme.typography.button.fontSize,
    borderRadius: "3px",
    padding: "11px 24px 10px 20px",
    border: "1px solid #C5CDD3",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "36px",
      backgroundColor: "#FFFFFF",
      fontSize: "16px",
    },
  },
  gap: {
    width: 16,
    display: "inline-block",
  },
}));

interface IProps {
  address: string;
  balance: string;
  onDisconnect: () => void;
  icon: string;
}

export const AccountInfo = (props: IProps) => {
  const classes = useStyles();
  const [baseToken, setBaseToken] = useState<string>("ETH");

  const { address, balance, onDisconnect } = props;

  const { networkId } = useConnectedWeb3Context();

  useEffect(() => {
    networkId === 137 ? "MATIC" : networkId === 56 ? "BNB" : "ETH";
    switch (networkId) {
      case 137:
        setBaseToken("MATIC");
        break;
      case 56:
        setBaseToken("BNB");
        break;
      case 80001:
        setBaseToken("MATIC");
        break;
      default:
        setBaseToken("ETH");
    }
  }, [networkId]);

  return (
    <div className={classes.root}>
      <div className={classes.infoDiv}>
        <MyChip
          className={classes.chip}
          label={`${formatBigNumber(balance)} ${baseToken}`}
        />
        <div className={classes.gap}></div>
        <MyChip className={classes.chip} label={shortenAddress(address)} />
      </div>

      <Button
        classes={{ root: classes.button }}
        disableElevation
        onClick={onDisconnect}
        startIcon={<ClearIcon />}
      >
        Disconnect
      </Button>
    </div>
  );
};
