export enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
  WalletLink = "walletlink",
  Fortmatic = "fortmatic",
  Portis = "portis",
}

export enum EPoolType {
  Private,
  BaseHolder,
  Public,
}
