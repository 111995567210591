import { Button, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import * as React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, Route, Switch } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  appBar: {
    width: `calc(100% - ${theme.custom.drawerWidth})`,
    marginLeft: theme.custom.drawerWidth,
    backgroundColor: "white",
  },
  toolbar: {
    color: "#1C2535",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
    boxShadow: "inset 0px -1px 0px #E8EBED",
    justifyContent: "space-between",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
  },
  backLink: {
    textDecoration: "none",
  },
  backLinkText: {
    color: "#1C2535",
    fontSize: "16px",
    lineHeight: "24px",
  },
}));

interface IProps {
  className?: string;
}

export const AdminHeader = (props: IProps) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.appBar, props.className)}
      elevation={0}
      position="fixed"
    >
      <Toolbar className={classes.toolbar}>
        <Switch>
          <Route
            exact={true}
            path="/admin/applications/:id"
            render={() => (
              <div className={classes.backDiv}>
                <Link className={classes.backLink} to="/admin/applications">
                  <Button>
                    <ChevronLeftIcon />
                    <span className={classes.backLinkText}>Back to List</span>
                  </Button>
                </Link>
              </div>
            )}
          />
        </Switch>
      </Toolbar>
    </AppBar>
  );
};
