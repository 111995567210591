import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import useCommonStyles from "styles/common";
import { LabelLinearProgress } from "components/Progress";
import { PoolStatusChip } from "components/Chip";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #E8EBED",
    marginBottom: "12px",
    padding: "16px",

    "&:hover": {
      borderColor: "#59B5FF",
    },
  },

  header: {
    fontSize: "14px",
  },
  headerStart: {},
  headerImg: {
    width: "20px",
    height: "auto",
    marginRight: "4px",
  },
  headerEnd: {
    display: "flex",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    marginBottom: "12px",
  },
  ratioDiv: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  footer: {},
  progress: {
    flexBasis: "100%",
  },
  status: {},

  gap: {
    width: "12px",
  },
}));

interface IProps {
  className?: string;
  id: string;
  name?: string;
  ratio?: number;
  access?: string;
  category?: string;
  progress?: number;
  status?: string;
  symbol?: string;
  symbolPath?: string;
}

export const PoolCard = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const progress = props.progress || 0;
  const status = props.status || "active";

  const history = useHistory();

  const handleClick = () => {
    history.push(`/pool/${props.id}`);
  };

  return (
    <div
      className={clsx(
        classes.root,
        props.className,
        commonClasses.borderRadius6,
        commonClasses.cardShadow
      )}
      onClick={handleClick}
    >
      <div className={clsx(classes.header, commonClasses.spread)}>
        <div className={clsx(classes.headerStart, commonClasses.row)}>
          <img
            alt="header img"
            className={classes.headerImg}
            src={`/imgs/pool/${props.symbolPath}`}
          />
          {props.name}
        </div>
        <div className={classes.headerEnd}>
          <div>{props.access}</div>
          <div className={classes.gap} />
          <div>{props.category}</div>
        </div>
      </div>
      <div className={classes.body}>
        <div className={clsx(classes.ratioDiv, commonClasses.row)}>
          <img alt="eth img" src={`/imgs/pool/eth.svg`} />1 ETH ={" "}
          {`${props.ratio} ${props.symbol}`}
        </div>
      </div>
      <div className={clsx(classes.footer, commonClasses.row)}>
        <div className={classes.progress}>
          <LabelLinearProgress value={progress} />
        </div>
        <div className={classes.gap} />
        <div className={classes.status}>
          <PoolStatusChip status={status} />
        </div>
      </div>
    </div>
  );
};
