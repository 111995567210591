import { Card, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { IApplication } from "store/types";

interface IProps {
  application: IApplication;
}

export const ApplicationCard = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();

  const tag = (status: string) => {
    let color;
    let tagTittle;
    if (status === "pending") {
      color = "gray";
      tagTittle = "PENDING";
    } else if (status === "approved") {
      color = "green";
      tagTittle = "APPROVED";
    } else {
      color = "red";
      tagTittle = "REJECTED";
    }
    return <div className={`${color} ${classes.ticker}`}>{tagTittle}</div>;
  };

  return (
    <Card
      className={classes.root}
      onClick={() =>
        history.push(
          `/pool/form/${props.application._id}`,
          props.application._id
        )
      }
      variant="outlined"
    >
      <div className={classes.title}>{props.application.projectName}</div>
      <p>
        {/* Rising: <span>$ {props.application.totalRaise || "N/A"}</span> */}
      </p>
      <div>{tag(props.application.status)}</div>
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    // minWidth: 275,
    background: "transparent",
    border: "1px solid #E8EBED",
    padding: "24px 17px",
    boxShadow: "0px 12px 20px -8px rgb(5 43 72 / 12%)",
    borderRadius: "16px",
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": {
      border: "1px solid #59B5FF",
      boxShadow: "0px 16px 24px -12px rgba(89, 181, 255, 0.24)",
    },
  },
  title: {
    marginTop: "4px",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "bold",
    color: "#1C2535",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8EBED",
    overflow: "hidden",
  },
  ticker: {
    width: "max-content",
    fontSize: "0.65rem",
    padding: "0.5rem",
    color: "#fff",
    borderRadius: "0.25rem",
    "&.red": {
      background: "#dc3545",
    },
    "&.gray": {
      background: "#808080",
    },
    "&.green": {
      background: "green",
    },
  },
}));
