import { LoadingScreen } from "components";
import { AdminLayout } from "layouts/AdminLayout";
import { ApplicationLayout } from "layouts/ApplicationLayout";
import { AuthLayout } from "layouts/AuthLayout";
import { FormLayout } from "layouts/FormLayout";
import { StakingLayout } from "layouts/StakingLayout";
import React, { Fragment, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const routes = [
  // {
  //   exact: true,
  //   path: "/admin/login",
  //   layout: AuthLayout,
  //   component: lazy(() => import("pages/admin/Login")),
  // },
  // {
  //   exact: true,
  //   path: "/admin/register",
  //   layout: AuthLayout,
  //   component: lazy(() => import("pages/admin/Register")),
  // },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() => import("pages/Application/ResetPassword")),
  },
  {
    exact: true,
    path: "/pools/details/:id",
    component: lazy(() => import("pages/PoolDetailsPage")),
  },
  {
    exact: true,
    path: "/staking/details/:id",
    component: lazy(() => import("pages/StakingDetailsPage")),
  },
  {
    path: "/admin",
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: "/admin/applications",
        component: lazy(() => import("pages/admin/Applications")),
      },
      {
        exact: true,
        path: "/admin/applications/:id",
        component: lazy(() => import("pages/admin/ApplicationDetail")),
      },
      {
        exact: true,
        path: "/admin/pools",
        component: lazy(() => import("pages/admin/AdminPools")),
      },
      {
        exact: true,
        path: "/admin/stakings",
        component: lazy(() => import("pages/admin/AdminStakings")),
      },
      {
        exact: true,
        path: "/admin/pools/:id",
        component: lazy(() => import("pages/admin/AdminPoolDetail")),
      },
      {
        exact: true,
        path: "/admin/logout",
        component: lazy(() => import("pages/admin/Logout")),
      },
      {
        path: "/admin",
        // eslint-disable-next-line
        component: () => <Redirect to="/admin/applications" />,
      },
    ],
  },
  // {
  //   path: "/",
  //   layout: DashboardLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: "/pool",
  //       component: lazy(() => import("pages/dashboard/pool")),
  //     },
  //     {
  //       exact: true,
  //       path: "/pool/:id",
  //       component: lazy(() => import("pages/dashboard/PoolDetail")),
  //     },
  //     {
  //       exact: true,
  //       path: "/applications",
  //       component: lazy(() => import("pages/dashboard/Applications")),
  //     },
  //     {
  //       exact: true,
  //       path: "/apply",
  //       component: lazy(() => import("pages/admin/Apply")),
  //     },
  //     {
  //       path: "*",
  //       // eslint-disable-next-line
  //       component: () => <Redirect to="/pool" />,
  //     },
  //   ],
  // },
  {
    path: "/pool",
    layout: FormLayout,
    routes: [
      {
        exact: true,
        path: "/pool/apply",
        component: lazy(() => import("pages/admin/Apply")),
      },
      {
        exact: true,
        path: "/pool/form/:id",
        component: lazy(() => import("pages/ApplyForm/DetailedForm")),
      },
      {
        exact: true,
        path: "/pool/:id",
        component: lazy(() => import("pages/ApplyForm/RevisionDetails")),
      },
      {
        path: "*",
        // eslint-disable-next-line
        component: () => <Redirect to="/applications" />,
      },
    ],
  },
  {
    path: "/staking",
    layout: StakingLayout,
    routes: [
      {
        exact: true,
        path: "/staking",
        component: lazy(() => import("pages/staking/apply")),
      },
      {
        exact: true,
        path: "/staking/apply",
        component: lazy(() => import("pages/staking/apply")),
      },
      {
        exact: true,
        path: "/staking/staking-list",
        component: lazy(() => import("pages/staking")),
      },
      {
        path: "*",
        // eslint-disable-next-line
        component: () => <Redirect to="/applications" />,
      },
    ],
  },
  {
    path: "/",
    layout: ApplicationLayout,
    routes: [
      {
        exact: true,
        path: "/applications",
        component: lazy(() => import("pages/Application/AllApplications")),
      },
      {
        exact: true,
        path: "/pools",
        component: lazy(() => import("pages/Application/Pools")),
      },
      {
        exact: true,
        path: "/pools/whitelisting/:id",
        component: lazy(() => import("pages/Application/Whitelisting")),
      },
      {
        exact: true,
        path: "/pools/whitelisting",
        component: lazy(
          () => import("pages/Application/Whitelisting/ViewWhiteListing")
        ),
      },
      {
        exact: true,
        path: "/pools/removewhitelisting/:id",
        component: lazy(() => import("pages/Application/BlackListing")),
      },
      {
        exact: true,
        path: "/pools/claim/:id",
        component: lazy(() => import("pages/Application/Claim")),
      },
      {
        exact: true,
        path: "/login",
        component: lazy(() => import("pages/Application/Login")),
      },
      {
        exact: true,
        path: "/register",
        component: lazy(() => import("pages/Application/Register")),
      },
      {
        exact: true,
        path: "/forget-password",
        component: lazy(() => import("pages/Application/ForgetPassword")),
      },
      // {
      //   exact: true,
      //   path: "/applications",
      //   component: lazy(() => import("pages/Application/AllApplications")),
      // },
      {
        path: "*",
        // eslint-disable-next-line
        component: () => <Redirect to="/applications" />,
      },
    ],
  },
  {
    path: "*",
    // eslint-disable-next-line
    component: () => <Redirect to="/" />,
  },
];

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: any, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            exact={route.exact}
            key={i}
            path={route.path}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default routes;
