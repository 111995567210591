import { Button, Modal, makeStyles } from "@material-ui/core";
import React from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import isWeekend from "date-fns/isThisMonth";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  content: {
    outline: "none",
    backgroundColor: "white",
    padding: theme.spacing(4),
    userSelect: `none`,
    borderRadius: "6px",

    [theme.breakpoints.down("xs")]: {
      width: "328px",
    },
  },
  title: {
    color: "#1C2535",
    fontSize: 24,
    lineHeight: "32px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  button: {
    width: 120,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  body: {
    margin: "24px 0px",
  },
  gap: {
    width: "16px",
    height: "16px",
  },
}));

interface IProps {
  visible: boolean;
  onClose?: () => void;
  dateValue: Date;
  handleOK?: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (datee: Date) => void;
}

export const DateModal = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { dateValue, handleChange, handleOK, onClose, visible } = props;

  return (
    <Modal
      className={clsx(classes.modal, commonClasses.modalShadow)}
      // disableBackdropClick
      onClose={onClose}
      open={visible}
    >
      <form onSubmit={handleOK}>
        <div className={classes.content}>
          <div className={classes.body}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker<Date>
                componentsProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
                onChange={(newValue) => {
                  if (newValue) handleChange(new Date(newValue));
                  else handleChange(new Date());
                }}
                openTo="day"
                orientation="landscape"
                renderInput={(params) => <TextField {...params} />}
                // shouldDisableDate={isWeekend}
                value={dateValue}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={clsx(commonClasses.button, classes.button)}
              color="primary"
              disableElevation
              type="submit"
              //   onClick={props.handleOK}
              variant="contained"
            >
              Ok
            </Button>
            <Button
              className={clsx(commonClasses.button, classes.button)}
              color="primary"
              disableElevation
              // type="submit"
              onClick={onClose}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
