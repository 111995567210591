import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useAppContext } from "contexts";
import { AppTypes, IApplication } from "store/types";
import useCommonStyles from "styles/common";
import {
  EAccess,
  FUND_TYPE_TEXT,
  PROJECT_CATEGORY_TEXT,
  PROJECT_STATUS_TEXT,
} from "config/constants";
import { useHistory, useParams } from "react-router-dom";
import {
  BootstrapInput,
  BootstrapLabel,
  UniversalInput,
} from "components/Input";
import { BootstrapSelect } from "components/Select";
import { getDateTime } from "utils/datetime";
import { processApplication } from "apis/application";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  header: {
    padding: "32px",
  },
  fields: {},
  field: {
    display: "flex",
    flexDirection: "column",

    marginBottom: "16px",
    width: "90%",
  },
  select: {
    height: "40px",
  },
  title: {
    marginBottom: "24px",
  },
  statusButtons: {
    paddingRight: "60px",
    justifyContent: "flex-end",
  },
  button: {
    minWidth: "150px",
    height: "46px",
    "&.reject": {
      // FD4975
      backgroundColor: "#FD4975",
      "&:disabled": {
        backgroundColor: "#FD4975",
      },
    },
    "&:disabled": {
      backgroundColor: "#446C8A",
    },
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
}));

interface IProps {
  className?: string;
}

export const AdminApplicationDetailPanel = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();

  const [inProgress, setInProgress] = useState<boolean>(false);

  const { dispatch, state } = useAppContext();

  const { id } = useParams<{ id: string }>();

  const application = useMemo(
    () => state.applications.find((app) => app._id === id),
    [id, state.applications]
  );

  const handleStatusChange = (action: string, id: string) => {
    setInProgress(true);
    {
      state &&
        processApplication(id, action, { token: state.user.token })
          .then((result) => {
            setInProgress(false);
            dispatch({
              type: AppTypes.AlertModal,
              payload: {
                show: true,
                title: "Application",
                body: `Successfully ${result.data.status}!`,
              },
            });
          })
          .then(() =>
            // For rerouting to My Application and modal off
            setTimeout(() => {
              dispatch({ type: AppTypes.AlertModal, payload: { show: false } });
              history.push("/applications");
            }, 3000)
          )
          .catch((err) => {
            if (err.response) {
              setInProgress(false);
              dispatch({
                type: AppTypes.AlertModal,
                payload: {
                  show: true,
                  title: "Application",
                  body: err.response.data.message,
                },
              });
            }
          })
          .finally(() => {
            setInProgress(false);
          });
    }
  };

  // const handleStatusChangea = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     // console.log("E", event);
  // if (!application) return;

  // setInProgress(true);

  // const action = event.target.checked ? "approve" : "reject";

  // processApplication(
  //   application.address!,
  //   action,
  //   {
  //     token: state.user.token,
  //   },
  //   { chainId: window.ethereum.chainId }
  // )
  //   .then((response) => {
  //     console.info(response.data);

  //     const app: IApplication = response.data;

  //     dispatch({
  //       type: AppTypes.SetApplication,
  //       payload: {
  //         application: app,
  //         applicationId: app.id,
  //       },
  //     });

  //     setInProgress(false);
  //   })
  //   .catch((err) => {
  //     console.error(err.response);
  //     if (err.response.status === 401) {
  //       dispatch({
  //         type: AppTypes.SetUser,
  //         payload: {
  //           token: "",
  //           user: {},
  //         },
  //       });
  //     }
  //   });
  //   },
  //   [application, dispatch, state.user.token]
  // );

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.header}>
        <div className={clsx(commonClasses.textMid, commonClasses.pin)}>
          Application Detail
        </div>
      </div>
      {application && (
        <>
          <Grid container>
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.title, commonClasses.textMid)}>
                Basic Information
              </div>
              <div className={classes.fields}>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="projectName">
                    Project Name
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="projectName"
                    required
                    type="text"
                    value={application.projectName}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="linkedin">
                    LinkedIn URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="linkedin"
                    required
                    type="text"
                    value={application.linkedin}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Website URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="website"
                    required
                    type="text"
                    value={application.website}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Whitepaper URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="whitepaper"
                    required
                    type="text"
                    value={application.whitepaper}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Whitelisting URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="whitelisting"
                    required
                    type="text"
                    value={application.whitelisting}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Twitter URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="twitter"
                    required
                    type="text"
                    value={application.twitter}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Telegram URL
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="telegram"
                    required
                    type="text"
                    value={application.telegram}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Token Information
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="tokenInfo"
                    required
                    type="text"
                    value={application.tokenInfo}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={clsx(classes.title, commonClasses.textMid)}>
                Project Information
              </div>
              <div className={classes.fields}>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="github">Github URL</BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="github"
                    required
                    type="text"
                    value={application.github}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="sod">
                    State of Development
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="sod"
                    required
                    type="text"
                    value={application.sod}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="totalRaise">
                    Total Raise
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="totalRaise"
                    required
                    type="text"
                    value={application.totalRaise}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="alreadyRaised">
                    Already Raised
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="alreadyRaised"
                    required
                    type="text"
                    value={application.alreadyRaised}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="approved">
                    Approved Status
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="approved"
                    required
                    type="text"
                    value={application.status}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="launchDate">
                    Launch Date
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="launchDate"
                    required
                    type="text"
                    value={application.launchDate}
                  />
                </div>
                <div className={classes.field}>
                  <BootstrapLabel htmlFor="chainSelect">
                    Description
                  </BootstrapLabel>
                  <BootstrapInput
                    disabled
                    fullWidth={true}
                    id="desc"
                    required
                    type="text"
                    value={application.desc}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                disabled={application.status === "approved" ? true : false}
                onClick={() => handleStatusChange("approve", application._id)}
                variant="contained"
              >
                Approve
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={`${classes.button} reject`}
                color="secondary"
                disabled={application.status === "approved" ? true : false}
                onClick={() => handleStatusChange("reject", application._id)}
                variant="contained"
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
