import { LANDING_URL } from "./constants";

export interface IMenuItem {
  external?: boolean;
  openBlank?: boolean;
  link?: string;
  name: string;
  type?: string;
  onClick?: () => void;
}

export const MENU_FOR_USER = [
  // {
  //   link: "/applications",
  //   name: "IDO Application",
  // },
  {
    link: "/pools",
    name: "Pools",
  },
  {
    link: "/staking",
    name: "Staking",
  },
  {
    link: "/staking/staking-list",
    name: "Staking List",
  },
];

export const ALLOWED_MENU_FOR_USER = [
  {
    link: "/applications",
    name: "IDO Application",
  },
  {
    link: "/pools",
    name: "Pools",
  },
];

export const APPLY_APPLICATION_MENU = [
  {
    link: "/applications",
    name: "IDO Application",
  },
];

export const USER_MENU = [
  {
    link: "/investments",
    name: "My Investments",
  },
  {
    link: "/applications",
    name: "My Applications",
  },
];

export const TOP_MENU = [
  {
    link: "/pool",
    name: "Pools",
  },
  {
    link: "/apply",
    name: "Apply for IDO",
  },
];

export const BOTTOM_MENU = [
  {
    external: true,
    openBlank: false,
    link: LANDING_URL,
    name: "Back to Main",
  },
  {
    external: true,
    openBlank: true,
    link: "https://ideaology-2.gitbook.io/what-is-ideaido/",
    name: "Documentation",
  },
  {
    external: true,
    openBlank: true,
    link: `${LANDING_URL}/terms`,
    name: "Terms and Privacy",
  },
  {
    external: true,
    openBlank: true,
    link: `${LANDING_URL}/contact`,
    name: "Contact",
  },
];
