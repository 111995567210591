import { Web3Provider } from "@ethersproject/providers";
import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { getMyApplications } from "apis/application";
import { useAppContext } from "contexts";
import { useEagerConnect, useInactiveListener } from "hooks/injectedHook";
import React, { Dispatch, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppActions, AppTypes, IApplication } from "store/types";
import { ApplicationCard } from "../ApplicaionCard";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { BigNumber, ethers } from "ethers";
import { ERC20Service } from "services/erc20";
import { ENVIRONMENT } from "config/networks_chainIds";

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

const getErrorMessage = (error: Error, dispatch: Dispatch<AppActions>) => {
  if (error instanceof NoEthereumProviderError) {
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.",
      },
    });
    // return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    dispatch({
      type: AppTypes.SwitchNetworkModal,
      payload: {
        show: true,
        // title: "MetaMask",
        // body: "You're connected to an unsupported network.",
        network: "1",
      },
    });
    // return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "Please authorize this website to access your Ethereum account.",
      },
    });
    // return "Please authorize this website to access your Ethereum account.";
  } else {
    // console.error("Hello", error);
    dispatch({
      type: AppTypes.AlertModal,
      payload: {
        show: true,
        title: "MetaMask",
        body: "Request already in progress. Check the console for more details.",
      },
    });
    // return "An unknown error occurred. Check the console for more details.";
  }
};

export const MyApplications = () => {
  const context = useWeb3React<Web3Provider>();
  const {
    account,
    activate,
    active,
    chainId,
    connector,
    deactivate,
    error,
    library: provider,
  } = context;

  const [activatingConnector, setActivatingConnector] = React.useState<any>();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  // useEffect
  const currentConnector = injected;
  const activating = currentConnector === activatingConnector;
  const connected = currentConnector === connector;
  const disabled = !triedEager || !!activatingConnector || connected || !!error;

  useEffect(() => {
    setActivatingConnector(currentConnector);
    activate(injected);
  }, [activate, currentConnector]);

  const [inProgress, setInProgress] = useState<boolean>(false);
  // const [userIDAblance, setUserIDAblance] = useState<number>(0);

  const classes = useStyles();
  const history = useHistory();
  const { dispatch, state } = useAppContext();
  if (error) {
    getErrorMessage(error, dispatch);
  }
  // console.log("State", state);
  useEffect(() => {
    setInProgress(true);

    getMyApplications({
      token: state.user.token,
    })
      .then((response) => {
        const filteredData = response.data.filter(
          (item: IApplication) => item.poolCreated === false
        );
        setInProgress(false);
        dispatch({
          type: AppTypes.SetApplications,
          payload: filteredData,
        });
      })
      .catch((err) => {
        console.error(err);
        setInProgress(false);
        if (err.response) {
          console.error(err.response);
          if (err.response.status === 401) {
            dispatch({
              type: AppTypes.SetUser,
              payload: {
                token: "",
                user: {},
              },
            });
          }
        }
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [state.user.token, dispatch]);

  // useEffect(() => {
  //   getIDAbalance();
  // }, [account]);

  // const getIDAbalance = async () => {
  //   if (!account || !provider) {
  //     const currentConnector = injected;
  //     setActivatingConnector(currentConnector);
  //     activate(injected);
  //     return;
  //   }

  //   const erc20Service = new ERC20Service(
  //     provider,
  //     account,
  //     process.env.REACT_APP_IDEA_CONTRACT_ADDRESS || ""
  //   );

  //   try {
  //     const balance = await erc20Service.getBalanceOf(account);

  //     setUserIDAblance(parseInt(BigNumber.from(balance).toString()) || 0);
  //   } catch (error) {
  //     setUserIDAblance(0);
  //   }
  // };

  const gotoApply = () => {
    // if (
    //   userIDAblance < 10000 &&
    //   !process.env.REACT_APP_LANDING_URL?.includes("staging")
    // ) {
    //   dispatch({
    //     type: AppTypes.AlertModal,
    //     payload: {
    //       show: true,
    //       title: "Low Balance",
    //       body: "Your IDA balance is below 10000",
    //     },
    //   });
    // } else {
    history.push("/pool/apply");
    // }
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid className={classes.root}>
      <Grid container justify="space-between">
        <Grid item>
          <h3>My Applications</h3>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            color="primary"
            onClick={gotoApply}
            variant="contained"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      <span className={classes.bottomBorder}></span>
      <Grid className={classes.cardContainer} container spacing={2}>
        {state &&
          state.applications.map((app) => {
            return (
              <Grid item key={app._id} lg={3} md={6} xs={12}>
                <ApplicationCard application={app} />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    minHeight: "calc(100vh - 64px)",

    display: "flex",
    flexDirection: "column",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  button: {
    minWidth: "150px",
    height: "46px",
  },
  bottomBorder: {
    borderBottom: "1px solid",
  },
  cardContainer: {
    marginTop: "18px",
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
}));
