import { parseEther } from "@ethersproject/units";
import { ReactComponent as CoinbaseSVG } from "assets/svgs/wallet/coinbase.svg";
import { ReactComponent as FormaticSVG } from "assets/svgs/wallet/fortmatic.svg";
import { ReactComponent as MetaMaskSVG } from "assets/svgs/wallet/metamask-color.svg";
import { ReactComponent as PortisSVG } from "assets/svgs/wallet/portis.svg";
import { ReactComponent as WalletConnectSVG } from "assets/svgs/wallet/wallet-connect.svg";
import { ConnectorNames } from "utils/enums";
import { NetworkId } from "types";

export const STORAGE_KEY_SETTINGS = "settings";
export const STORAGE_KEY_CONNECTOR = "CONNECTOR";
export const LOGGER_ID = "ideapad";

export const STORAGE_KEY_USER = "USER";

export const TEST_MODE = Boolean(Number(process.env.REACT_APP_TEST || "1"));

export const DEFAULT_NETWORK_ID: NetworkId = TEST_MODE ? 80001 : 137;
export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;

export const TOKEN_PRICE_DECIMALS = 18;
export const WEIGHT_DECIMALS = 18;

export const WALLET_ICONS: { [key in ConnectorNames]: React.ElementType } = {
  [ConnectorNames.Injected]: MetaMaskSVG,
  [ConnectorNames.WalletConnect]: WalletConnectSVG,
  [ConnectorNames.WalletLink]: CoinbaseSVG,
  [ConnectorNames.Fortmatic]: FormaticSVG,
  [ConnectorNames.Portis]: PortisSVG,
};

export const API_URL = process.env.REACT_APP_API_URL || "/v1";
export const LANDING_URL =
  process.env.REACT_APP_LANDING_URL || "https://ideaology.io";

export const SUBGRAPH_URL =
  process.env.REACT_APP_SUBGRAPH_URL ||
  "https://api.thegraph.com/subgraphs/name/ace-contributor/ideapad";

export const RINKBY_URL =
  process.env.REACT_APP_RINKBY_URL ||
  "https://api.studio.thegraph.com/query/10618/ideapad2/v0.0.1";

export const IPFS_CONFIG = {
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
};

export const IPFS_IMAGE_ENDPOINT = `https://cloudflare-ipfs.com/ipfs/`;

export enum EWeiToken {
  ETH,
  BNB,
  MATIC,
  USDT,
}

export const WEI_TOKEN_TEXT: { [key in EWeiToken]: string } = {
  [EWeiToken.ETH]: "ETH",
  [EWeiToken.BNB]: "BNB",
  [EWeiToken.MATIC]: "MATIC",
  [EWeiToken.USDT]: "USDT",
};

export enum EFundType {
  ETH,
  IDEA,
  BNB,
  MATIC,
  USDT,
}

export const FUND_TYPE_TEXT: { [key in EFundType]: string } = {
  [EFundType.ETH]: "ETH",
  [EFundType.IDEA]: "IDEA",
  [EFundType.BNB]: "BNB",
  [EFundType.MATIC]: "MATIC",
  [EFundType.USDT]: "USDT",
};

export enum EAccess {
  Private,
  Public,
}

export enum ENetwork {
  ERC20 = "eth",
  BSC = "bsc",
  POLYGON = "polygon",
}

export const NETWORK_CURRENCY: { [key in ENetwork]: string } = {
  [ENetwork.ERC20]: "ETH",
  [ENetwork.BSC]: "BNB",
  [ENetwork.POLYGON]: "MATIC",
};

export enum EProjectStatus {
  INITIAL,
  WHITEPAPER,
  EARLY_DEVELOPMENT,
  LATE_DEVELOPMENT,
  TO_LAUNCH,
  LAUNCHED,
}

export const PROJECT_STATUS_TEXT: { [key in EProjectStatus]: string } = {
  [EProjectStatus.INITIAL]: "Just an initial idea",
  [EProjectStatus.WHITEPAPER]: "Idea with White Paper",
  [EProjectStatus.EARLY_DEVELOPMENT]: "In early development",
  [EProjectStatus.LATE_DEVELOPMENT]: "In late stage of development",
  [EProjectStatus.TO_LAUNCH]: "Ready to launch",
  [EProjectStatus.LAUNCHED]: "Already launched",
};

export enum EProjectCategory {
  DEFI = "defi",
  GAME = "game",
  EXCHANGE = "exchange",
  COLLECTIBLE = "collectible",
  MARKETPLACE = "marketplace",
  SOCIAL = "social",
  OTHER = "other",
}

export const PROJECT_CATEGORY_TEXT: { [key in EProjectCategory]: string } = {
  [EProjectCategory.DEFI]: "DeFi",
  [EProjectCategory.GAME]: "Games",
  [EProjectCategory.EXCHANGE]: "Exchanges",
  [EProjectCategory.COLLECTIBLE]: "Collectibles",
  [EProjectCategory.MARKETPLACE]: "Marketplaces",
  [EProjectCategory.SOCIAL]: "Social",
  [EProjectCategory.OTHER]: "Other",
};

export const weiTokensPerNetwork: { [key in ENetwork]: EWeiToken[] } = {
  [ENetwork.ERC20]: [EWeiToken.ETH],
  [ENetwork.BSC]: [EWeiToken.BNB],
  [ENetwork.POLYGON]: [EWeiToken.MATIC, EWeiToken.USDT],
};

export const fundTypesPerNetwork: { [key in ENetwork]: EFundType[] } = {
  [ENetwork.ERC20]: [EFundType.ETH, EFundType.IDEA],
  [ENetwork.BSC]: [EFundType.BNB],
  [ENetwork.POLYGON]: [EFundType.MATIC, EFundType.USDT],
};

export const DEFAULT_MIN_WEI = parseEther("0.1");
export const DEFAULT_MAX_WEI = parseEther("1");

export const NETWORK_CONFIG = TEST_MODE
  ? {
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x13881",
          chainName: "Mumbai Testnet",
          nativeCurrency: {
            name: "Polygon",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
          blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        },
      ],
    }
  : {
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x89",
          chainName: "Matic Mainnet",
          nativeCurrency: {
            name: "Polygon",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
          blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        },
      ],
    };

export const LOGO_IMAGE_FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB

export const ADDRESSES_ENUMS = {
  POOL_FACTORY_CONTRACT_ADDRESS:
    process.env.REACT_APP_POOL_FACTORY_CONTRACT_ADDRRESS,
  BNB_PROVIDER: process.env.REACT_APP_BNB_PROVIDER,
  IDEA_CONTRANCT_ADDRESS: process.env.REACT_APP_IDEA_CONTRACT_ADDRESS,
};
