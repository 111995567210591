import { Button, Modal, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";

import { AppTypes } from "store/types";
import { useAppContext } from "contexts";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    outline: "none",
    backgroundColor: "white",
    padding: theme.spacing(4),
    userSelect: `none`,
    borderRadius: "6px",
  },
  title: {
    color: "#1C2535",
    fontSize: 24,
    lineHeight: "32px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  button: {
    width: 120,
  },
  rejectButton: {
    background: "#E93547",
    color: "#FFFFFF",

    "&:hover": {
      backgroundColor: "#CA1628",
    },
  },
  gap: {
    width: "16px",
  },
}));

interface IProps {
  visible: boolean;
  onClose: () => void;
  approve: boolean;
}

export const ActionModal = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { onClose, visible } = props;

  const { dispatch } = useAppContext();

  const handleOK = () => {
    dispatch({ type: AppTypes.ActionModal, payload: { show: false } });
  };

  const handleCancel = () => {
    dispatch({ type: AppTypes.ActionModal, payload: { show: false } });
  };

  return (
    <Modal
      className={clsx(classes.modal, commonClasses.modalShadow)}
      disableBackdropClick
      onClose={onClose}
      open={visible}
    >
      <div className={classes.content}>
        <Typography className={classes.title} component="div">
          Are you sure you want to {props.approve ? "approve" : "reject"}?
        </Typography>
        <div>
          <p>...</p>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            className={clsx(
              commonClasses.button,
              classes.button,
              props.approve ? "" : classes.rejectButton
            )}
            color="primary"
            disableElevation
            onClick={handleOK}
            variant="contained"
          >
            {props.approve ? "Approve" : "Reject"}
          </Button>
          <div className={classes.gap}></div>
          <Button
            className={clsx(commonClasses.button, classes.button)}
            color="primary"
            disableElevation
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
