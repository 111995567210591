import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { BorderlessTableCell, StripedTableRow } from "components/Table";
import { useAppContext } from "contexts";
import { AppTypes, IApplication } from "store/types";
import { IOSSwitch } from "components/Switch";
import useCommonStyles from "styles/common";
import { useHistory } from "react-router-dom";
import {
  IPool,
  deleteApplication,
  deletePools,
  getApplications,
  getMyPools,
  processApplication,
} from "apis/application";
import { DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    minHeight: "calc(100vh - 64px)",

    display: "flex",
    flexDirection: "column",
  },
  tableWrapper: {
    marginBottom: "20px",
  },
  tableContainer: {
    boxShadow: "none",
  },
  table: {
    minWidth: 650,
  },
  noContentDiv: {
    height: "100%",
  },
  header: {
    padding: "32px",
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
}));

interface IProps {
  className?: string;
}

const headers = [
  { name: "Project Name", style: { width: "20%" } },
  { name: "Description", style: { width: "20%" } },
  { name: "Category", style: { width: "20%" } },
  { name: "Access", style: { width: "20%" } },
  // { name: "Action", style: { width: "10%" } },
  { name: "Delete", style: { width: "10%" } },
];

export const AdminPoolsPanel = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [poolData, setPoolData] = useState<IPool[]>();
  const [inProgress, setInProgress] = useState<boolean>(false);

  const { dispatch, state } = useAppContext();

  useEffect(() => {
    setInProgress(true);

    getMyPools({ token: state.user.token })
      .then((response) => {
        setPoolData(response.data);
        // console.log("Pools data", response.data);
        setInProgress(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response) {
          console.error(err.response);
          if (err.response.status === 401) {
            dispatch({
              type: AppTypes.SetUser,
              payload: {
                token: "",
                user: {},
              },
            });
          }
        }
      });
  }, [dispatch, state.user.token]);

  // const handleSwitch = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   address: string
  // ) => {
  //   setInProgress(true);
  //   const action = event.target.checked ? "approve" : "reject";
  //   processApplication(
  //     address,
  //     action,
  //     { token: state.user.token }
  //     // { chainId: window.ethereum.chainId }
  //   )
  //     .then((response) => {
  //       // console.log("Process Applicaiton called processApplication");
  //       // console.log("Response OF Pool", response.data);

  //       const application: IApplication = response.data;

  //       const newApplications = state.applications.map((app) =>
  //         app._id === application._id ? application : app
  //       );

  //       dispatch({
  //         type: AppTypes.SetApplications,
  //         payload: newApplications,
  //       });

  //       setInProgress(false);
  //     })
  //     .catch((err) => {
  //       console.error(err.response);
  //       if (err.response.status === 401) {
  //         dispatch({
  //           type: AppTypes.SetUser,
  //           payload: {
  //             token: "",
  //             user: {},
  //           },
  //         });
  //       }
  //     });
  // };

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>, pool: IPool) => {
    history.push(`/admin/pools/${pool._id}`, pool);
  };

  const handleDelete = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    address: string | undefined
  ) => {
    event.stopPropagation();
    if (!address) {
      dispatch({
        type: AppTypes.AlertModal,
        payload: {
          show: true,
          title: "Pool",
          body: "No Pool Found",
        },
      });
      return;
    } else {
      setInProgress(true);
      deletePools(address, { token: state.user.token }).then(() => {
        dispatch({
          type: AppTypes.AlertModal,
          payload: {
            show: true,
            title: "Pool",
            body: "Successfully deleted!",
          },
        });
        const newPools = poolData?.filter((p) => p._id !== address);
        setPoolData(newPools);
        setInProgress(false);
      });
    }
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={clsx(commonClasses.textMid, commonClasses.pin)}>
          Pools
        </div>
      </div>
      {poolData && poolData.length === 0 ? (
        <div
          className={clsx(
            commonClasses.center,
            classes.noContentDiv,
            commonClasses.textSmall
          )}
        >
          No Pool
        </div>
      ) : (
        <div
          className={clsx(
            classes.tableWrapper,
            commonClasses.border,
            commonClasses.borderRadius6,
            commonClasses.containerShadow
          )}
        >
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <StripedTableRow>
                  {headers.map((header) => (
                    <BorderlessTableCell key={header.name} style={header.style}>
                      {header.name}
                    </BorderlessTableCell>
                  ))}
                </StripedTableRow>
              </TableHead>
              <TableBody>
                {poolData &&
                  poolData.map((pool: IPool) => {
                    const row = pool;

                    return (
                      <StripedTableRow
                        hover={true}
                        key={row._id}
                        onClick={(event) => handleClick(event, row)}
                      >
                        <BorderlessTableCell>
                          {row.applicationId?.projectName || "N/A"}
                        </BorderlessTableCell>
                        <BorderlessTableCell>
                          description
                          {/* {row.description} */}
                        </BorderlessTableCell>
                        <BorderlessTableCell>
                          category
                          {/* {row.category} */}
                        </BorderlessTableCell>
                        <BorderlessTableCell>{row.access}</BorderlessTableCell>
                        {/* <BorderlessTableCell>
                            {row.approved === true ? null : (
                              <IOSSwitch
                                checked={row.approved}
                                onChange={(event) => handleSwitch(event, row.id)}
                                onClick={(event) => event.stopPropagation()}
                              />
                            )}
                          </BorderlessTableCell> */}
                        <BorderlessTableCell>
                          <DeleteOutline
                            onClick={(event) => handleDelete(event, row._id)}
                          />
                        </BorderlessTableCell>
                      </StripedTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};
