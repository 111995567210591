import Register from "pages/admin/Register";
import React from "react";

export const UserApplicationRegister = () => {
  return (
    <div>
      <Register isApplying loginRoute={"/login"} />
    </div>
  );
};
