import {
  IKnownTokenData,
  INetwork,
  IToken,
  KnownContract,
  KnownToken,
  NetworkId,
} from "types";
import { NULL_ADDRESS } from "utils/token";
import { entries } from "utils/type-utils";

import {
  ADDRESSES_ENUMS,
  DEFAULT_NETWORK_ID,
  INFURA_PROJECT_ID,
} from "./constants";
import { ENVIRONMENT, mainnet_networkIds } from "./networks_chainIds";

export const networkIds = {
  MAINNET: 1,
  RINKEBY: 4,
  BSC: 56,
  MATIC: 137,
  MUMBAI: 80001,
  ROPSTEN: 3,
  BscScan: 97,
} as const;

const networks: { [K in NetworkId]: INetwork } = {
  [networkIds.MAINNET]: {
    label: "Mainnet",
    url: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    contracts: {
      factory: ADDRESSES_ENUMS.POOL_FACTORY_CONTRACT_ADDRESS || "",
    },
  },
  [networkIds.ROPSTEN]: {
    label: "Testnet",
    url: `https://ropsten.infura.io/v3/c3950d5fe0814e3e9bd30ba0fcd21aa2`,
    contracts: {
      factory: "0xf71bf60BE25eD87BfaA4723D4F2DCB45526727aC",
    },
  },
  // [networkIds.BscScan]: {
  //   label: "BscScan Testnet",
  //   url: `https://data-seed-prebsc-2-s3.binance.org:8545/`,
  //   contracts: {
  //     factory: "0x74f0eB8ed47139927E73917730C4c94d91989b91",
  //   },
  // },
  [networkIds.BscScan]: {
    label: "BscScan",
    url: ADDRESSES_ENUMS.BNB_PROVIDER || "",
    contracts: {
      factory: ADDRESSES_ENUMS.POOL_FACTORY_CONTRACT_ADDRESS || "",
    },
  },
  [networkIds.RINKEBY]: {
    label: "Rinkeby",
    url: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    contracts: {
      // factory: "0xde551984e29adf3c99392fa88c5a6766312c7c01",
      factory: "0x29d2f1a91b72e1076b3da349c69de52b0bc91bac",
    },
  },
  [networkIds.BSC]: {
    label: "BSC",
    url: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    contracts: {
      factory: ADDRESSES_ENUMS.POOL_FACTORY_CONTRACT_ADDRESS || "",
    },
  },
  [networkIds.MUMBAI]: {
    label: "Mumbai Testnet",
    url: "https://rpc-mumbai.maticvigil.com/",
    contracts: {
      factory: "0xabc2030d40c97487987d8e90df09a2dc9e422c42",
    },
  },
  [networkIds.MATIC]: {
    label: "Matic Mainnet",
    url: "https://rpc-mainnet.maticvigil.com/",
    contracts: {
      factory: "0x654A1846C5F164C64fdcc91520a978e45fdD0eD0", // To be changed
    },
  },
};

export const supportedNetworkIds = Object.keys(networks).map(
  Number
) as NetworkId[];

export const supportedNetworkURLs = entries(networks).reduce<{
  [networkId: number]: string;
}>(
  (acc, [networkId, network]) => ({
    ...acc,
    [networkId]: network.url,
  }),
  {}
);

export const knownTokens: { [name in KnownToken]: IKnownTokenData } = {
  matic: {
    symbol: "MATIC",
    decimals: 18,
    name: "Polygon",
    image:
      "https://github.com/trustwallet/assets/blob/master/blockchains/polygon/info/logo.png",
    addresses: {
      [networkIds.MUMBAI]: NULL_ADDRESS,
      [networkIds.MATIC]: NULL_ADDRESS,
    },
    coingeckoTokenId: "matic-network",
  },
  wmatic: {
    symbol: "WMATIC",
    decimals: 18,
    name: "Wrapped Matic",
    image:
      "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png",
    addresses: {
      [networkIds.MUMBAI]: "0x8377da69E99e6DB975fCa7677C2f07C5792a4acc",
      [networkIds.MATIC]: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    },
    coingeckoTokenId: "wmatic",
  },
  usdt: {
    symbol: "USDT",
    decimals: 18,
    name: "Tether USD",
    image:
      "https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/0xde3A24028580884448a5397872046a019649b084/logo.png",
    addresses: {
      [networkIds.MUMBAI]: "0xd7a65c9B81Fec5F73E8eC1996B1c541cC059b0F3",
      [networkIds.MATIC]: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
    coingeckoTokenId: "tether",
  },
};

const validNetworkId = (networkId: number): networkId is NetworkId => {
  return networks[networkId as NetworkId] !== undefined;
};

export const getToken = (networkId: number, tokenId: KnownToken): IToken => {
  if (!validNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }

  const token = knownTokens[tokenId];
  if (!token) {
    throw new Error(`Unsupported token id: '${tokenId}'`);
  }

  const address = token.addresses[networkId];

  if (!address) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }

  return {
    address,
    decimals: token.decimals,
    symbol: token.symbol,
    image: token.image,
  };
};

export const getEtherscanURL = (networkId: number): string => {
  if (!validNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }
  if (networkId === mainnet_networkIds.ETH.MAINNET && ENVIRONMENT === "prod")
    return "https://etherscan.io";
  return "";
};

export const getContractAddress = (
  networkId: any,
  contract: KnownContract
): string => {
  if (!validNetworkId(networkId)) {
    throw new Error(`Unsupported network id: '${networkId}'`);
  }
  return networks[networkId].contracts[contract];
};
