import {
  Button,
  CircularProgress,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";

import { AppTypes } from "store/types";
import { useAppContext } from "contexts";
import { BootstrapInput, BootstrapLabel } from "components/Input";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  content: {
    outline: "none",
    backgroundColor: "white",
    width: 400,
    padding: theme.spacing(4),
    userSelect: `none`,
    borderRadius: "6px",

    [theme.breakpoints.down("xs")]: {
      width: "328px",
    },
  },
  title: {
    color: "#1C2535",
    fontSize: 24,
    lineHeight: "32px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  button: {
    width: 120,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  body: {
    margin: "24px 0px",
  },
  gap: {
    width: "16px",
    height: "16px",
  },
}));

interface IProps {
  visible: boolean;
  onClose?: () => void;
  body?: string;
  title?: string;
  handleOK?: (e: React.FormEvent<HTMLFormElement>) => void;
  setAmountValue?: React.Dispatch<React.SetStateAction<number>>;
  amountValue?: number;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
}

export const PriceModal = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { onClose, visible } = props;
  const [show, setShow] = useState<boolean>(props.visible);

  const { dispatch } = useAppContext();

  //   const handleOK = () => {
  //     // visible = false;
  //   };

  return (
    <Modal
      className={clsx(classes.modal, commonClasses.modalShadow)}
      // disableBackdropClick
      onClose={onClose}
      open={visible}
    >
      <form onSubmit={props.handleOK}>
        <div className={classes.content}>
          <Typography className={classes.title} component="div">
            {props.title}
          </Typography>
          <div className={classes.body}>
            {/* <p>{props.body}</p> 
        // For buying type    `~
        */}
            <BootstrapLabel htmlFor="amount">Amount</BootstrapLabel>
            <BootstrapInput
              fullWidth={true}
              id="amount"
              //   inputProps={{ min: 1 }}
              inputProps={{ min: 0.0000001, step: ".0000001" }}
              onChange={(e) => {
                if (e.target.value) {
                  props.setAmountValue &&
                    props?.setAmountValue(parseInt(e.target.value));
                } else {
                  props.setAmountValue && props?.setAmountValue(0);
                }
              }}
              required
              type="text"
              value={props?.amountValue}
            />
          </div>
          <div className={classes.buttonDiv}>
            <Button
              className={clsx(commonClasses.button, classes.button)}
              color="primary"
              disableElevation
              type="submit"
              //   onClick={props.handleOK}
              variant="contained"
            >
              {props.loading && <CircularProgress color="inherit" size={14} />}
              {!props.loading && "OK"}
            </Button>
            <Button
              className={clsx(commonClasses.button, classes.button)}
              color="primary"
              disableElevation
              // type="submit"
              onClick={() =>
                props && props.setShowModal && props.setShowModal(false)
              }
              variant="contained"
            >
              Close
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
