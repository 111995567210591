import { Container, makeStyles } from "@material-ui/core";
import Login from "pages/admin/Login";
import ResetPassword from "pages/admin/ResetPassword";
import React from "react";

export const UserApplicationResetPassword = () => {
  const classes = useStyles();

  return (
    <div>
      <ResetPassword />
    </div>
  );
};

const useStyles = makeStyles(() => ({}));
