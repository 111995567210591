import { Button, makeStyles } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  root: {
    ".mobile-menu &": {
      width: "100%",
      marginBottom: "36px",
    },
  },
  button: {
    height: "40px",
    fontSize: theme.typography.button.fontSize,
    borderRadius: "3px",
    padding: "11px 24px 10px 20px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

interface IProps {
  className?: string;
  onClick: () => void;
}

export const ConnectWalletButton = (props: IProps) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      classes={{ root: classes.button }}
      color="primary"
      disableElevation
      onClick={() => {
        props.onClick();
      }}
      startIcon={<AccountBalanceWalletIcon />}
      variant="contained"
    >
      Connect Wallet
    </Button>
  );
};
