import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { useAppContext } from "contexts";
import { AppTypes } from "store/types";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  drawer: {
    width: theme.custom.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.custom.drawerWidth,
    backgroundColor: "#052B48",
    justifyContent: "space-between",
  },
  toolbar: theme.mixins.toolbar,
  item: {
    color: "#9FD2F9",
    padding: "8px 10px 8px 13px",
    borderRadius: "6px",

    "&:hover": {
      background: "rgba(213, 237, 255, 0.15)",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: `calc(${theme.custom.appHeaderHeight} / 2)`,
  },
  active: {
    background: "#0C71BC !important",

    "& span": {
      color: "white",
    },
  },
  link: {
    cursor: "pointer",
  },
}));

const navItems = [
  {
    link: "/admin/applications",
    name: "Applications",
  },
  {
    link: "/admin/pools",
    name: "Pools",
  },
  {
    link: "/admin/stakings",
    name: "Stakings",
  },
];

interface IProps {
  className?: string;
}

export const AdminSidenav = (props: IProps) => {
  const location = useLocation();
  const classes = useStyles();

  const { dispatch, state } = useAppContext();

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, props.className)}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
    >
      <div>
        <div className={clsx(classes.toolbar, classes.logoWrapper)}>
          <a href="/">
            <img
              alt="logo"
              className={classes.logo}
              src="/imgs/logo/logo-white.svg"
            />
          </a>
        </div>
        <List>
          {navItems.map((item, index) => (
            <ListItem component={Link} key={item.name} to={item.link}>
              <ListItemText
                className={clsx(
                  classes.item,
                  location.pathname === item.link ? classes.active : ""
                )}
                primary={item.name}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <List>
          {state && state.user.token && (
            <ListItem className={classes.link}>
              <ListItemText
                className={clsx(classes.item)}
                onClick={() => {
                  localStorage.removeItem("USER");
                  dispatch({
                    type: AppTypes.SetUser,
                    payload: {
                      token: "",
                      user: {},
                    },
                  });
                }}
                primary="Logout"
              />
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
};
