import { Web3Provider } from "@ethersproject/providers";
import { ThemeProvider } from "@material-ui/styles";
import { Web3ReactProvider } from "@web3-react/core";
import { AppProvider, ConnectedWeb3, GlobalProvider } from "contexts";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "routes";
import { createTheme } from "theme";
import { RINKBY_URL, SUBGRAPH_URL } from "config/constants";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import "./App.css";

// const client = new ApolloClient({
//   uri: SUBGRAPH_URL,
//   cache: new InMemoryCache(),
// });

// const rinkbyClient = new ApolloClient({
//   uri: RINKBY_URL,
//   cache: new InMemoryCache(),
// });

// Changes 17-11-21

// const subGraphLink = new HttpLink({
//   uri: "https://api.thegraph.com/subgraphs/name/ace-contributor/ideapad",
// });
// const weatherLink = new HttpLink({
//   uri: "https://graphql-weather-api.herokuapp.com/",
//   useGETForQueries: true,
// });

// const countryLink = new HttpLink({
//   uri: "https://countries.trevorblades.com/",
// });

// const pokemonLink = new HttpLink({
//   uri: "https://graphqlpokemon.favware.tech/",
//   useGETForQueries: true,
// });

// const client = new ApolloClient({
//   link: ApolloLink.split(
//     (operation) => operation.getContext().clientName === "graph",
//     subGraphLink,
//     ApolloLink.split(
//       (operation) => operation.getContext().clientName === "country",
//       countryLink,
//       ApolloLink.split(
//         (operation) => operation.getContext().clientName === "weather",
//         weatherLink,
//         pokemonLink
//       )
//     )
//   ),
//   cache: new InMemoryCache(),
// });

// Changes 18-11-21

const GRAPH_URL =
  "https://api.thegraph.com/subgraphs/name/ace-contributor/ideapad1"; // for not getting data
// "https://api.thegraph.com/subgraphs/name/ace-contributor/ideapad";

const client1 = new ApolloClient({
  uri: GRAPH_URL,
  cache: new InMemoryCache(),
});

const client2 = new ApolloClient({
  uri: GRAPH_URL,
  cache: new InMemoryCache(),
});

const client3 = new ApolloClient({
  uri: GRAPH_URL,
  cache: new InMemoryCache(),
});

const client4 = new ApolloClient({
  uri: GRAPH_URL,
  cache: new InMemoryCache(),
});

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const theme = createTheme();

function App() {
  const [clientURL, setClientURL] = useState<any>(client1);
  React.useEffect(() => {
    setTimeout(() => {
      if ("0x61" == window.ethereum && window.ethereum.chainId) {
        setClientURL(client1);
      } else if ("0x38" == window.ethereum && window.ethereum.chainId) {
        setClientURL(client2);
      } else if ("0x89" == window.ethereum && window.ethereum.chainId) {
        setClientURL(client3);
      } else {
        setClientURL(client1);
      }
    }, 1000);
  }, []);

  return (
    <ApolloProvider client={clientURL}>
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ConnectedWeb3>
            <GlobalProvider>
              <AppProvider>
                <BrowserRouter>{renderRoutes(routes as any)}</BrowserRouter>
              </AppProvider>
            </GlobalProvider>
          </ConnectedWeb3>
        </Web3ReactProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
