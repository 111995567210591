import { ICollection, ICollectionItem } from "utils/collection";
import { BigNumber } from "ethers";
import { IToken } from "types";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum AppTypes {
  SetPools = "POOLS",
  SetPoolParticipants = "POOL_PARTICIPANTS",
  JoinModal = "JOIN_MODAL",
  JoinPool = "JOIN_POOL",
  SetPool = "SET_POOL",
  FundPool = "FUND_POOL",
  ActionModal = "ACTION_MODAL",
  WarningModal = "WARNING_MODAL",
  WithdrawModal = "WITHDRAW_MODAL",
  SetUser = "SET_UESR",
  SetApplications = "SET_APPLICATIONS",
  SetApplication = "SET_APPLICATION",
  SetTotalPools = "SET_TOTAL_POOLS",
  AlertModal = "ALERT_MODAL",
  SwitchNetworkModal = "SWITCH_NETWORK_MODAL",
  PriceModal = "PRICE_MODAL",
}

type AppPayload = {
  [AppTypes.SetPools]: IPool[];
  [AppTypes.SetPoolParticipants]: {
    poolId: string;
    participants: IParticipant[];
  };
  [AppTypes.JoinPool]: {
    poolId: string;
    participant: IParticipant;
  };
  [AppTypes.JoinModal]: {
    show: boolean;
    pool?: IPool;
    amount?: number;
  };
  [AppTypes.SetPool]: {
    pool: IPool;
    poolId: string;
  };
  [AppTypes.FundPool]: {
    fund: boolean;
    poolId: string;
  };
  [AppTypes.ActionModal]: {
    show: boolean;
    approve?: boolean;
  };
  [AppTypes.WarningModal]: {
    show: boolean;
    callback?: () => void;
  };
  [AppTypes.WithdrawModal]: {
    show: boolean;
  };
  [AppTypes.SetUser]: {
    user: IUserData;
    token: string;
  };
  [AppTypes.SetApplications]: IApplication[];
  [AppTypes.SetApplication]: {
    applicationId: string;
    application: IApplication;
  };
  [AppTypes.SetTotalPools]: ITotalPools;
  [AppTypes.AlertModal]: {
    show: boolean;
    body?: string;
    title?: string;
    address?: string;
    // onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose?: () => void;
  };
  [AppTypes.SwitchNetworkModal]: {
    show: boolean;
    body?: string;
    title?: string;
    network: string;
    // onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose?: () => void;
  };
  [AppTypes.PriceModal]: {
    show: boolean;
    body?: string;
    title?: string;
    // onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose?: () => void;
  };
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export interface IParticipant extends ICollectionItem {
  address: string;
  amount: number;
  baseAmount: number;
  timestamp: string;
  status: string;
}

export interface IParticipantCollection extends ICollection {
  [id: string]: IParticipant;
}

export interface IMetaData {
  icon?: string;
  category?: string;
  projectName?: string;
  description?: string;
  twitter?: string;
  medium?: string;
  website?: string;
}

export interface IPool extends ICollectionItem {
  symbolPath: string;
  name: string;
  ratio: number;
  symbol: string;
  access: string;
  category: string;
  progress: number;
  status: string;
  funded?: boolean;
  participants?: IParticipantCollection;

  // should map from subgraph
  address?: string;
  totalMembers?: number;
  tokenTarget?: BigNumber;
  totalOwed?: BigNumber;
  weiRaised?: BigNumber;
  minWei?: BigNumber;
  maxWei?: BigNumber;
  startTime?: number;
  endTime?: number;
  meta?: string;
  poolType?: number;
  token?: string;
  poolState?: string;
  weiToken?: string;

  // should map from application

  // meta
  metaData?: IMetaData;
  tokenData?: IToken;
}

export interface IPoolCollection extends ICollection {
  [id: string]: IPool;
}

export interface IApplication {
  _id: string;
  projectName?: string;
  website?: string;
  whitepaper?: string;
  twitter?: string;
  telegram?: string;
  tokenInfo?: string;
  desc?: string;
  linkedin?: string;
  github?: string;
  sod?: string;
  totalRaise?: number;
  alreadyRaised?: string;
  launchDate?: string;
  status: string;
  // projectName?: string;
  projectNameB?: string;
  email: string;
  password?: string;
  description?: string;
  chain?: string;
  address?: string;
  access?: string;
  startDate?: string;
  endDate?: string;
  claimDate?: string;
  category?: number;
  websiteUrl?: string;
  mediumUrl?: string;
  telegramHandle?: string;
  twitterID?: string;
  iconUrl?: string;
  fee?: number;
  tradeValue?: number;
  // totalRaise?: number;
  minRaise?: number;
  minAlloc?: number;
  maxAlloc?: number;
  approved: boolean;
  projectStatus?: number;
  teamAccess?: number;
  // alreadyRaised?: number;
  tokenUseCase?: string;
  fundType?: number;
  createdPoolId?: number;
  // status?: boolean;
  genId: number;
  poolCreated: boolean;
  whitelisting: string;
  pools?: any;
}

export interface IAlertModalState {
  visible: boolean;
  title?: string;
  body?: string;
  address?: string;
}

export interface ISwitchNetworkModalState {
  visible: boolean;
  title?: string;
  body?: string;
  network: string;
}

export interface IPriceModalState {
  visible: boolean;
  title?: string;
  body?: string;
}

export interface IJoinModalState {
  visible: boolean;
  pool?: IPool;
  amount?: number;
}

// TODO: update structure, put props into separated object
// action - approve
// withdraw - none, should contain poolId, address, tokens, etc
export interface IModals {
  action: boolean;
  approve: boolean;
  withdraw: boolean;

  alert: IAlertModalState;
  switchnetworkmodal: ISwitchNetworkModalState;
  price: IPriceModalState;
  join: IJoinModalState;
}

export interface IUserData {
  email?: string;
  id?: string;
  isEmailVerified?: boolean;
  name?: string;
  role?: string;
}

export interface IUser {
  token?: string;
  user: IUserData;
}

export interface ITotalPools {
  all: number;
  top: number;
  self: number;
}

export interface IState {
  pools: IPoolCollection;
  modals: IModals;

  user: IUser;

  applications: IApplication[];

  totalPools: ITotalPools;
}
