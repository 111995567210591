import React, { Dispatch, useReducer } from "react";

import { AppActions, AppTypes, IState } from "store/types";
import { appReducer, initialState } from "store/appReducer";

import {
  ActionModal,
  AlertModal,
  JoinModal,
  PriceModal,
  SwitchNetworkModal,
  WithdrawModal,
} from "components";

export type TContext = {
  state: IState;
  dispatch: Dispatch<AppActions>;
};

const AppContext = React.createContext<TContext>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export const AppProvider = (props: IProps) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const closeJoinModal = () => {
    dispatch({ type: AppTypes.JoinModal, payload: { show: false } });
  };

  const closeActionModal = () => {
    dispatch({ type: AppTypes.ActionModal, payload: { show: false } });
  };

  const closeWithdrawModal = () => {
    dispatch({ type: AppTypes.WithdrawModal, payload: { show: false } });
  };

  const closeAlertModal = () => {
    dispatch({ type: AppTypes.AlertModal, payload: { show: false } });
  };

  const closePriceModal = () => {
    dispatch({ type: AppTypes.PriceModal, payload: { show: false } });
  };

  const closeSwitchNetworkModal = () => {
    dispatch({
      type: AppTypes.SwitchNetworkModal,
      payload: { show: false, network: "" },
    });
  };

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
      {state.modals.join && (
        <JoinModal
          onClose={closeJoinModal}
          visible={state.modals.join.visible}
        />
      )}
      {state.modals.action && (
        <ActionModal
          approve={state.modals.approve}
          onClose={closeActionModal}
          visible={state.modals.action}
        />
      )}
      {state.modals.withdraw && (
        <WithdrawModal
          onClose={closeWithdrawModal}
          visible={state.modals.withdraw}
        />
      )}
      {state.modals.alert && (
        <AlertModal
          address={state.modals.alert.address}
          body={state.modals.alert.body}
          onClose={closeAlertModal}
          title={state.modals.alert.title}
          visible={state.modals.alert.visible}
        />
      )}
      {state.modals.switchnetworkmodal && (
        <SwitchNetworkModal
          body={state.modals.switchnetworkmodal.body}
          network={state.modals.switchnetworkmodal.network}
          onClose={closeSwitchNetworkModal}
          title={state.modals.switchnetworkmodal.title}
          visible={state.modals.switchnetworkmodal.visible}
        />
      )}
      {state.modals.price && (
        <PriceModal
          body={state.modals.price.body}
          onClose={closePriceModal}
          title={state.modals.price.title}
          visible={state.modals.price.visible}
        />
      )}
    </AppContext.Provider>
  );
};
