import {
  AppActions,
  AppTypes,
  IParticipantCollection,
  IPoolCollection,
  IState,
} from "./types";

import { STORAGE_KEY_USER } from "config/constants";

import { makeCollectionFromArray } from "utils/collection";

export const initialState: IState = {
  applications: [],
  pools: {},
  modals: {
    join: {
      visible: false,
      amount: 0,
    },
    action: false,
    approve: false,
    withdraw: false,
    alert: {
      visible: false,
      body: "",
      title: "",
      address: "",
    },
    switchnetworkmodal: {
      visible: false,
      body: "",
      title: "",
      network: "",
    },
    price: {
      visible: false,
      body: "",
      title: "",
    },
  },
  user: JSON.parse(localStorage.getItem(STORAGE_KEY_USER) || "{}"),
  totalPools: {
    all: 0,
    top: 0,
    self: 0,
  },
};

export const appReducer = (state: IState, action: AppActions): IState => {
  switch (action.type) {
    case AppTypes.JoinModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          join: {
            visible: action.payload.show,
            pool: action.payload.pool,
            amount: action.payload.amount,
          },
        },
      };
    case AppTypes.ActionModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          action: action.payload.show,
          approve: action.payload.approve || false,
        },
      };
    case AppTypes.WithdrawModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          withdraw: action.payload.show,
        },
      };
    case AppTypes.AlertModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          alert: {
            visible: action.payload.show,
            body: action.payload.body,
            title: action.payload.title,
            address: action.payload.address,
          },
        },
      };
    case AppTypes.SwitchNetworkModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          switchnetworkmodal: {
            visible: action.payload.show,
            body: action.payload.body,
            title: action.payload.title,
            network: action.payload.network,
          },
        },
      };
    case AppTypes.PriceModal:
      return {
        ...state,
        modals: {
          ...state.modals,
          alert: {
            visible: action.payload.show,
            body: action.payload.body,
            title: action.payload.title,
          },
        },
      };

    case AppTypes.SetPools:
      return {
        ...state,
        pools: makeCollectionFromArray(action.payload) as IPoolCollection,
      };
    case AppTypes.SetPoolParticipants:
      return {
        ...state,
        pools: {
          ...state.pools,
          [action.payload.poolId]: {
            ...state.pools[action.payload.poolId],
            participants: makeCollectionFromArray(
              action.payload.participants
            ) as IParticipantCollection,
          },
        },
      };
    case AppTypes.JoinPool:
      return {
        ...state,
        pools: {
          ...state.pools,
          [action.payload.poolId]: {
            ...state.pools[action.payload.poolId],
            participants: {
              ...state.pools[action.payload.poolId].participants,
              [action.payload.participant.id]: action.payload.participant,
            },
          },
        },
      };
    case AppTypes.SetPool:
      return {
        ...state,
        pools: {
          ...state.pools,
          [action.payload.poolId]: action.payload.pool,
        },
      };
    case AppTypes.FundPool:
      return {
        ...state,
        pools: {
          ...state.pools,
          [action.payload.poolId]: {
            ...state.pools[action.payload.poolId],
            funded: action.payload.fund,
          },
        },
      };
    case AppTypes.SetUser:
      localStorage.setItem(
        STORAGE_KEY_USER,
        JSON.stringify({
          token: action.payload.token,
          user: action.payload.user,
        })
      );
      return {
        ...state,
        user: {
          token: action.payload.token,
          user: action.payload.user,
        },
      };
    case AppTypes.SetApplications:
      return {
        ...state,
        applications: action.payload,
      };
    case AppTypes.SetApplication: {
      const newApplications = state.applications.map((app) =>
        app._id === action.payload.applicationId
          ? action.payload.application
          : app
      );
      return {
        ...state,
        applications: newApplications,
      };
    }
    case AppTypes.SetTotalPools:
      return {
        ...state,
        totalPools: action.payload,
      };
    default:
      return state;
  }
};
