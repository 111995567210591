import { AppTypes } from "store/types";
import { useAppContext } from "contexts";
import { API_URL } from "config/constants";
import axios from "axios";

export interface IFields {
  projectName?: string;
  website?: string;
  whitepaper?: string;
  twitter?: string;
  telegram?: string;
  tokenInfo?: string;
  desc?: string;
  linkedin?: string;
  github?: string;
  sod?: string;
  // totalRaise?: number;
  alreadyRaised?: number;
  launchDate?: string;
  network?: string;
  // projectNameB?: string;
  // email: string;
  // password?: string;
  // description?: string;
  chain?: string;
  fundType?: number;
  address?: string;
  access?: number;
  tradeValue?: number;
  minAlloc?: number;
  maxAlloc?: number;
  fee?: number;
  whitelisting: string;
  // startDate?: string;
  // endDate?: string;
  // claimDate?: string;
  // category?: string;
  // websiteUrl?: string;
  // mediumUrl?: string;
  // telegramHandle?: string;
  // twitterID?: string;
  // iconUrl?: string;
  // minRaise?: number;
  // projectStatus?: number;
  // alreadyRaised?: number;
  // teamAccess?: number;
  // tokenUseCase?: string;
  // weiToken?: string;
}

export interface IPool {
  account?: string;
  category: string;
  chain: string;
  chainId?: number;
  fundType: number;
  address: string;
  access: number;
  tradeValue: number;
  minAlloc: number;
  maxAlloc: number;
  fee: number;
  startDate?: string;
  endDate?: string;
  claimDate?: string;
  totalRaise?: number;
  applicationId: any;
  _id?: string;
  status?: string;
  user?: string;
  tokenPrice?: number;
  meta?: {
    logo?: string;
    cover?: string;
  };
  poolId: number;
  deployStatus: boolean;
  vesting?: number;
}

export interface IHeader {
  token?: string;
}

export interface IBody {
  chainId: string;
}

// let isRefreshing = false;
// const refreshSubscribers: any = [];

// const subscribeTokenRefresh = (cb: any) => {
//   refreshSubscribers.push(cb);
// };

// const onRrefreshed = (token: any) => {
//   refreshSubscribers.map((cb: any) => cb(token));
// };

// export const tokenReissue = () => {
//   const baseUrl = `${API_URL}/auth/refresh-tokens`;
//   return axios({
//     method: "POST",
//     url: baseUrl,
//     headers: {
//       "Content-Type": "application/json",
//       "x-refresh-token": localStorage.getItem("refresh_token"),
//     },
//   })
//     .then((res) => res.data)
//     .catch((error) => {
//       throw error;
//     });
// };

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { config: _config, response } = error;
//     const originalRequest = _config;
//     const networkError = {
//       response: { data: { status: 500, code: "unknown" } },
//     };
//     if (response === undefined) return Promise.reject(networkError);
//     if (
//       response.status === 401 &&
//       (response.data.code === 30002 || response.data.code === 30003)
//     ) {
//       const { dispatch, state } = useAppContext();
//       if (!isRefreshing) {
//         isRefreshing = true;
//         tokenReissue().then(
//           (res) => {
//             isRefreshing = false;
//             console.log(res, "=======hahad====");
//           },
//           (err) => {
//             console.log(err, "=======d===hahahaha=");

//             dispatch({
//               type: AppTypes.SetUser,
//               payload: {
//                 token: "",
//                 user: {},
//               },
//             });
//           }
//         );
//       }

//       const retryOrigReq = new Promise((resolve) => {
//         subscribeTokenRefresh((token: any) => {
//           originalRequest.headers["x-access-token"] = token;
//           resolve(axios(originalRequest));
//         });
//       });
//       return retryOrigReq;
//     }

//     if (response.status === 401) {
//       localStorage.clear();
//       window.location.href = "/signin";
//     }
//     return Promise.reject(error);
//   }
// );

export const createPool = (fields: IPool, header: IHeader) => {
  return axios.post(
    `${API_URL}/pools`,
    {
      ...fields,
    },
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

export const getMyPools = (header: IHeader) => {
  return axios.get(`${API_URL}/pools`, {
    params: {
      limit: 1000,
    },
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

export const processPool = (id: string, action: string, header: IHeader) => {
  return axios.post(
    `${API_URL}/pools/${id}/${action}`,
    {},
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

export const createApplication = (fields: IFields, header: IHeader) => {
  return axios.post(
    `${API_URL}/misc/applications`,
    {
      ...fields,
      // meta,
      // creator: account,
    },
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

export const processApplication = (
  id: string,
  action: string,
  header: IHeader
) => {
  return axios.post(
    `${API_URL}/misc/applications/${id}/${action}`,
    {},
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

export const getApplications = (header: IHeader) => {
  return axios.get(`${API_URL}/misc/applications`, {
    params: { limit: 1000 },
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

export const getApplicationById = (id: string, header: IHeader) => {
  return axios.get(`${API_URL}/misc/applications/${id}`, {
    params: { limit: 1000 },
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

export const updateApplicationAfterDeploy = (body: any, header: IHeader) => {
  return axios.post(
    `${API_URL}/misc/applications/update-application-status-after-deploy`,
    {
      body,
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

export const getMyApplications = (header: IHeader) => {
  return axios.get(`${API_URL}/misc/my-applications`, {
    params: {
      limit: 1000,
    },
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

export const updatePoolAfterDeploy = (
  id: string,
  contractAddress: string,
  header: IHeader
) => {
  // // console.log("contractAddress", contractAddress);
  return axios.post(
    `${API_URL}/pools/${id}/deploy`,
    {
      contractAddress,
    },
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

export const deleteApplication = (id: string, header: IHeader) => {
  return axios.delete(`${API_URL}/misc/applications/${id}`, {
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

export const deletePools = (id: string, header: IHeader) => {
  return axios.delete(`${API_URL}/pools/${id}`, {
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

//for cryptocompare

export const getCryptoCompare = (from: string, to: string) => {
  return axios.get(
    `https://min-api.cryptocompare.com/data/price?fsym=${from}&tsyms=${to}`,
    {
      headers: {
        Authorization: `Apikey ${process.env.REACT_APP_CRYPTOEXCHANGE_API_KEY}`,
      },
    }
  );
};

// for details pool

export const getPoolsById = (id: string) => {
  return axios.get(`${API_URL}/pools/details/${id}`);
};

// apply for Staking
export const createStaking = (
  body: { endDate: Date; amount: number; percentage: number },
  header: IHeader
) => {
  return axios.post(
    `${API_URL}/stakings`,
    {
      ...body,
    },
    { headers: { Authorization: `Bearer ${header.token}` } }
  );
};

// get All stakings
export const allStakings = (header: IHeader) => {
  return axios.get(`${API_URL}/stakings`, {
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

// get All stakings
export const registerforstaking = (id: string, header: IHeader) => {
  return axios.post(
    `${API_URL}/stakings/register/${id}`,
    {
      body: {},
    },
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

// get All not claim stakings
export const claimStakings = (header: IHeader) => {
  return axios.get(`${API_URL}/stakings/claim`, {
    headers: { Authorization: `Bearer ${header.token}` },
  });
};

//update staking record
export const updateStakingafterDeploy = (id: string, header: IHeader) => {
  return axios.post(
    `${API_URL}/stakings/deploy/${id}`,
    {
      body: {},
    },
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

//update claim in staking record
export const updateStakingaClaim = (id: string) => {
  return axios.post(`${API_URL}/stakings/stakingclaim/${id}`, {
    body: {},
  });
};

//update claim in staking record
export const updateClaim = (id: string) => {
  return axios.post(`${API_URL}/stakings/claim/${id}`, {
    body: {},
  });
};

export const getStakingById = (id: string) => {
  return axios.get(`${API_URL}/stakings/details/${id}`);
};

export const requestedUserStaking = (
  header: IHeader,
  page: number,
  limit?: number
) => {
  return axios.get(
    `${API_URL}/users/user-request-staking-list?role=user&adminAllowStaking=false&page=${page}${
      limit ? "&limit=" + limit : ""
    }`,
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

export const userRequestToAdminForAddressUpdate = (
  header: IHeader,
  body: { dummyAddress_staking: string; adminAllowStaking: boolean },
  userId: string
) => {
  return axios.patch(
    `${API_URL}/users/user-request-to-admin-address-update/${userId}`,
    body,
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

export const adminApproveForAddressChange = (header: IHeader, id: string) => {
  return axios.post(
    `${API_URL}/users/user-request-staking-list`,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};

export const requestAdminChangeChangeAddress = (
  header: IHeader,
  id: string
) => {
  return axios.post(
    `${API_URL}/users/user-request-staking-list`,
    {
      id,
    },
    {
      headers: { Authorization: `Bearer ${header.token}` },
    }
  );
};
