import { CircularProgress, Container } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { getApplicationById } from "apis/application";
import clsx from "clsx";
import { useAppContext } from "contexts";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { IApplication } from "store/types";
import useCommonStyles from "styles/common";

interface IDATA {
  id: string;
}

export const Revision = () => {
  let data: IDATA = useParams();
  const [appData, setAppData] = useState<IApplication>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { state } = useAppContext();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const history = useHistory();

  useEffect(() => {
    setInProgress(true);
    getApplicationById(data.id, {
      token: state.user.token,
    })
      .then((result) => {
        setInProgress(false);
        setAppData(result.data);
      })
      .catch((err) => {
        setInProgress(false);
        // console.log(err);
      })
      .finally(() => {
        setInProgress(false);
      });
    return () => {
      data = { id: "" };
    };
  }, [data]);

  const message = (type: string): string => {
    if (type === "approved") {
      return " Hurray! your application approved. Go for Further Step";
    } else if (type === "rejected") {
      return " Sorry! application rejected";
    } else {
      return " Your application is in process.";
    }
  };

  if (inProgress) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  if (!appData) {
    return (
      <div className={clsx(classes.root)}>
        <Container className={classes.container} maxWidth="lg">
          <div
            className={clsx(
              commonClasses.containerShadow,
              commonClasses.border,
              commonClasses.borderRadius16,
              commonClasses.clearFix,
              classes.content
            )}
          >
            <div className={commonClasses.lineSeparator}></div>
            <div className={classes.innerContent}>Something Went wrong</div>
          </div>
        </Container>
      </div>
    );
  }

  if (appData.status === "approved") {
    return (
      <Redirect to={{ pathname: "/pool/form", state: { id: appData._id } }} />
    );
  }

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container} maxWidth="lg">
        <div onClick={() => history.push("/")}>
          <p className={classes.backIconContainer}>
            <ArrowBack className={classes.backIcon} /> Go Back
          </p>
        </div>
        {appData && (
          <div
            className={clsx(
              commonClasses.containerShadow,
              commonClasses.border,
              commonClasses.borderRadius16,
              commonClasses.clearFix,
              classes.content
            )}
          >
            <div className={clsx(commonClasses.textBig, commonClasses.pin)}>
              Revision
            </div>
            <div className={commonClasses.lineSeparator}></div>
            <div className={classes.innerContent}>
              <h3>
                Project Name:{" "}
                <span className={classes.fontNormal}>
                  {appData.projectName}
                </span>
              </h3>
              <h3>
                Status:
                <span className={classes.fontNormal}>
                  {message(appData.status)}
                </span>
              </h3>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {},
  container: {
    color: "#1C2535",
    marginTop: "40px",
    marginBottom: "40px",
  },
  content: {
    padding: "56px 0px 60px 80px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px 30px 30px",
    },
  },
  innerContent: {
    padding: "0px 80px 0px 0px",

    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 0px",
    },
  },
  fields: {
    // flexBasis: "552px",
    flex: 1,
  },
  field: {
    width: "100%",
    marginTop: "24px",
    marginBottom: "12px",
  },
  select: {
    height: "40px",
    marginTop: theme.spacing(1),
  },
  buttonDiv: {
    marginTop: "30px",
    marginRight: "80px",
    float: "right",
  },
  button: {
    maxWidth: "200px",
    height: "40px",
  },
  feedback: {
    color: "#D81159",
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "20px",
  },
  datetimeField: {
    // height: "40px",
    // marginTop: "10px",
    // minWidth: "250px",
    border: "1px solid #ced4da",
    flexDirection: "inherit",
    borderRadius: "4px",
  },
  loader: {
    position: "absolute",
    top: "48%",
    left: "48%",
    zIndex: 5,
  },
  sections: {
    marginTop: "16px",
  },
  fontNormal: {
    fontWeight: 400,
  },
  backIconContainer: {
    display: "flex",
    alignItems: "center",
    opacity: "0.5",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  backIcon: {
    fontSize: "1rem",
    marginRight: "0.25rem",
  },
}));
