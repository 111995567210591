import { Hidden, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useAppContext } from "contexts";
import React from "react";
import { Redirect } from "react-router";
import useCommonStyles from "styles/common";
import {
  ApplicationHeader,
  ApplicationSidenav,
} from "../ApplicationLayout/components";

export const StakingLayout = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { state } = useAppContext();

  if (state.user.token === undefined || state.user.token.length === 0) {
    return <Redirect to="/login" />;
  }

  // if (state && state.user.user.role === "user") {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className={classes.root}>
      <ApplicationHeader />
      <Hidden xsDown>
        <ApplicationSidenav />
      </Hidden>
      <main className={clsx(classes.content, commonClasses.scroll)}>
        <div className={classes.toolbar} />
        {/* <Hidden xsUp>
        </Hidden> */}
        {props.children}
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: { display: "flex" },
  content: {
    flexGrow: 1,
    backgroundColor: "#F4F8FA",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("xs")]: {
      minHeight: "72px",
    },
  },
}));

interface IProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}
