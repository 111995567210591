import {
  Button,
  Hidden,
  InputAdornment,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import useCommonStyles from "styles/common";
import clsx from "clsx";

import { AppTypes } from "store/types";
import { useAppContext } from "contexts";
import { UniversalInput } from "components/Input";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end",
    },
  },
  content: {
    backgroundColor: "#F4F8FA",
    outline: "none",
    padding: theme.spacing(4),
    userSelect: `none`,
    borderRadius: "6px",
    width: "552px",

    [theme.breakpoints.down("xs")]: {
      width: "328px",
      padding: "24px",
    },
  },
  title: {
    color: "#1C2535",
    fontSize: "24px",
    lineHeight: "32px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  button: {
    width: 150,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  gap: {
    width: "16px",
    height: "16px",
  },
  infoDiv: {
    display: "flex",
    flexWrap: "wrap",

    paddingTop: 8,
    paddingBottom: 8,

    [theme.breakpoints.down("xs")]: {
      paddingTop: 24,
    },
  },
  amountDiv: {
    paddingTop: 8,
    paddingBottom: 32,
  },
  amountInput: {
    height: "40px",
  },
  addressDiv: {
    color: "#7F909F",
    paddingTop: 16,
    paddingBottom: 8,
  },
  suffix: {
    color: "#7F909F",
    fontSize: 11,
  },
  lineBreak: {
    flexBasis: "100%",
    height: "24px",
  },
}));

interface IProps {
  visible: boolean;
  onClose: () => void;
}

export const WithdrawModal = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { onClose, visible } = props;

  const { dispatch } = useAppContext();

  const handleOK = () => {
    dispatch({ type: AppTypes.WithdrawModal, payload: { show: false } });
  };

  const handleCancel = () => {
    dispatch({ type: AppTypes.WithdrawModal, payload: { show: false } });
  };

  return (
    <Modal
      className={classes.modal}
      disableBackdropClick
      onClose={onClose}
      open={visible}
    >
      <div className={clsx(classes.content, commonClasses.modalShadow)}>
        <Typography className={classes.title} component="div">
          Withdraw Unsold Tokens
        </Typography>
        <Hidden xsDown>
          <div className={clsx(classes.addressDiv, commonClasses.row)}>
            0x6908977CB0E1F30Bc884C54E28EA147053A3c37e&nbsp;&nbsp;
            <FileCopyOutlinedIcon />
          </div>
          <div className={commonClasses.separator}></div>
        </Hidden>
        <div className={clsx(classes.infoDiv, commonClasses.spread)}>
          <div>
            <div>Total Purchases</div>
            <div
              className={clsx(commonClasses.textBold, commonClasses.textMid)}
            >
              200
            </div>
          </div>
          <div>
            <div>Claimed Tokens</div>
            <div
              className={clsx(commonClasses.textBold, commonClasses.textMid)}
            >
              0
            </div>
          </div>
          <Hidden smUp>
            <div className={classes.lineBreak} />
          </Hidden>
          <div>
            <div>Tokens Left</div>
            <div
              className={clsx(commonClasses.textBold, commonClasses.textMid)}
            >
              24.000<span className={classes.suffix}>(DOOT)</span>
            </div>
          </div>
        </div>
        <div className={commonClasses.separator}></div>
        <div className={classes.amountDiv}>
          <span>Amount to Withdraw</span>
          <div>
            <UniversalInput
              className={classes.amountInput}
              endAdornment={
                <InputAdornment position="end">DOOT</InputAdornment>
              }
              fullWidth={true}
              placeholder="Enter the desired amount..."
              type="number"
            />
          </div>
        </div>
        <div className={classes.buttonDiv}>
          <Button
            className={clsx(commonClasses.button, classes.button)}
            color="primary"
            disableElevation
            onClick={handleOK}
            variant="contained"
          >
            Claim Tokens
          </Button>
          <div className={classes.gap}></div>
          <Button
            className={clsx(commonClasses.button, classes.button)}
            color="primary"
            disableElevation
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
