export interface ICollectionItem {
  id: string;
}

export interface ICollection {
  [id: string]: ICollectionItem;
}

export const makeCollectionFromArray = (
  arr: ICollectionItem[]
): ICollection => {
  return arr.reduce((col: ICollection, item: ICollectionItem) => {
    col[item.id] = item;
    return col;
  }, {});
};

export const makeArrayFromCollection = (
  col: ICollection
): ICollectionItem[] => {
  return Object.keys(col).map((key) => col[key]);
};
