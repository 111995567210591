import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { APPLY_APPLICATION_MENU, IMenuItem, MENU_FOR_USER } from "config/menu";

import { useAppContext } from "contexts";
import { AppTypes } from "store/types";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100vh",
  },
  drawer: {
    width: theme.custom.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.custom.drawerWidth,
    backgroundColor: "#052B48",
    height: "100%",

    justifyContent: "space-between",
  },
  toolbar: theme.mixins.toolbar,
  item: {
    color: "#9FD2F9",
    padding: "8px 10px 8px 13px",
    borderRadius: "6px",

    "&:hover": {
      background: "rgba(213, 237, 255, 0.15)",
    },
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: `calc(${theme.custom.appHeaderHeight} / 2)`,
  },
  active: {
    background: "#0C71BC !important",

    "& span": {
      color: "white",
    },
  },
  link: {
    cursor: "pointer",
  },
}));

interface IProps {
  className?: string;
}

export const ApplicationSidenav = (props: IProps) => {
  const location = useLocation();
  const classes = useStyles();

  const { dispatch } = useAppContext();

  const { state } = useAppContext();

  const renderList = (items: IMenuItem[]) => (
    <>
      {items.map((item) => (
        <ListItem component={Link} key={item.name} to={item.link}>
          <ListItemText
            className={clsx(
              classes.item,
              location.pathname === item.link ? classes.active : ""
            )}
            primary={item.name}
          />
        </ListItem>
      ))}
    </>
  );

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.root, classes.drawer, props.className)}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
    >
      <div>
        <div className={clsx(classes.toolbar, classes.logoWrapper)}>
          <Link to="/">
            <img
              alt="logo"
              className={classes.logo}
              src="/imgs/logo/logo-white.svg"
            />
          </Link>
        </div>
        <List>
          {renderList(APPLY_APPLICATION_MENU)}
          {state.user.token &&
            state.user.token?.length > 1 &&
            renderList(MENU_FOR_USER)}

          {/* : (
            <ListItem className={classes.link}>
              <ListItemText
                className={clsx(classes.item)}
                onClick={() => // console.log("Hello")}
                primary="Login"
              />
            </ListItem>
          )} */}
        </List>
      </div>

      <List>
        {state && state.user.token && (
          <ListItem className={classes.link}>
            <ListItemText
              className={clsx(classes.item)}
              onClick={() => {
                localStorage.removeItem("USER");
                dispatch({
                  type: AppTypes.SetUser,
                  payload: {
                    token: "",
                    user: {},
                  },
                });
              }}
              primary="Logout"
            />
          </ListItem>
        )}
      </List>
      {/* {state.user.token ? (
          <ListItem>
            <ListItemText
              className={clsx(classes.item)}
              onClick={() => // console.log("Hello")}
              primary="Logout"
            />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText
              className={clsx(classes.item)}
              onClick={() => // console.log("Hello")}
              primary="Login"
            />
          </ListItem>
        )} */}
      {/* {BOTTOM_MENU.map((item) => {
          if (item.external) {
            return (
              <a
                className="MuiListItem-root MuiListItem-gutters"
                href={item.link}
                key={item.name}
                rel="noreferrer noopener"
                target={item.openBlank ? "_blank" : "_self"}
              >
                <div className={clsx(classes.item, "MuiListItemText-root")}>
                  {item.name}
                </div>
              </a>
            );
          }
          return (
            <ListItem component={Link} key={item.name} to={item.link}>
              <ListItemText
                className={clsx(
                  classes.item,
                  location.pathname === item.link ? classes.active : ""
                )}
                primary={item.name}
              />
            </ListItem>
          );
        })} */}
    </Drawer>
  );
};
